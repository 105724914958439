<div class="row">
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Process</label>
      <select [(ngModel)]="processId" (change)="saveProcessFilterSelected();filterData()" class="form-control">
        <option *ngFor="let p of processFilter" [ngValue]="p.processId">{{p.processName}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <fieldset class="filter-type">
      <legend>Process Type Filter</legend>
      <div>
        <label class="radio-inline">
          <input type="radio" name="processTypeId10" [value]="2" [(ngModel)]="processTypeId" (change)="filterProcess();filterData()"> Fabrication
        </label>
        <label class="radio-inline">
          <input type="radio" name="processTypeId10" [value]="3" [(ngModel)]="processTypeId" (change)="filterProcess();filterData()"> Field
        </label>
      </div>
    </fieldset>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12 clear-weight-btn-container">
    <a (click) ="clearWeights()" class="btn btn-primary pull-right" >Clear Weights</a>
  </div>

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">REDUCTION OF OUTPUT PER HOUR</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Material Group</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.materialGroup}}</td>
                  <td [style.width.px]="120">
                    <qxm-input-percent
                      [myFormGroup]="dataForm"
                      myFormArrayName="dataUpdate"
                      [myFormGroupName]="i"
                      myFormControlName="percentAdjustment"
                      (acceptChange)="save();">
                    </qxm-input-percent>
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>
