<div class="content-wrapper">
  <section class="content-header">
    <h1>New Tenant</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li><a routerLink="/tenants"> Tenants</a></li>
      <li class="active">new tenant</li>
    </ol>
  </section>

  <section class="content">
    <form novalidate [formGroup]="form">
      <div class="row">
       <div class="col-sm-4 col-xs-12" style="background-color: white; margin-left: 10px;">

        <div class="form-group" [class.has-error]="company.invalid && (company.dirty || company.touched)">
          <label>Company <span class="text-danger">*</span></label>
          <input type="text" trim="blur" class="form-control" formControlName="company" maxlength="100" required>
          <ng-container *ngIf="company.invalid && (company.dirty || company.touched)">
            <span *ngIf="company.errors.required" class="help-block">Company is required.</span>
          </ng-container>
        </div>

        <div class="form-group" [class.has-error]="tenantName.invalid && (tenantName.dirty || tenantName.touched)">
           <label>Tenant Name (sub-domain) <span class="text-danger">*</span></label>
           <input type="text" trim="blur" class="form-control" formControlName="tenantName" maxlength="50" required>
           <ng-container *ngIf="tenantName.invalid && (tenantName.dirty || tenantName.touched)">
             <span *ngIf="tenantName.errors.required" class="help-block">Tenant name is required.</span>
             <span *ngIf="tenantName.errors.pattern" class="help-block">Invalid tenant name format. Tenant name should not contain special characters and upper case characters.</span>
             <span *ngIf="tenantName.errors.isExistTenant" class="help-block">{{error_message}}</span>
           </ng-container>
         </div>
         <!-- <div class="form-group" [class.has-error]="website.invalid && (website.dirty || website.touched)">
           <label>Website <span class="text-danger">*</span></label>
           <input type="text" trim="blur" class="form-control" formControlName="website" maxlength="100" required>
           <ng-container *ngIf="website.invalid && (website.dirty || website.touched)">
             <span *ngIf="website.errors.required" class="help-block">Website is required.</span>
             <span *ngIf="website.errors.pattern" class="help-block">Invalid url format of website.</span>
           </ng-container>
         </div>
         <div class="form-group" [class.has-error]="phone.invalid && (phone.dirty || phone.touched)">
           <label>Main Phone</label>
           <input type="tel" mask="(000) 000-0000" class="form-control" formControlName="phone" />
           <ng-container *ngIf="phone.invalid && (phone.dirty || phone.touched)">
             <span *ngIf="phone.errors" class="help-block">Invalid phone number format.</span>
           </ng-container>
         </div> -->
         <!-- <div class="form-group" *ngIf="tenantId.value > 0">
           <label><input type="checkbox" formControlName="active" /> Active</label>
         </div> -->
       </div>
       <!-- <div class="col-sm-4 col-xs-12" style="background-color: white; margin-left: 20px;">
         <h5 style="line-height: 1.5; text-decoration: underline">Available Chanel</h5>
         <div class="table-responsive" style="margin-bottom: 10px;">
           <table class="table table-bordered table-hover">
             <thead>
               <tr style="color: gray;">
                 <th class="text-center" [style.width.px]="110">Channel</th>
                 <th class="text-center" [style.width.px]="80">Active</th>
                 <th class="text-center">Cost Schema</th>
               </tr>
             </thead>
             <tbody>
               <ng-container formArrayName="channels">
                 <ng-container [formGroupName]="i" *ngFor="let item of form.get('channels')['controls']; let i = index;">
                   <tr>
                     <td class="text-center" [style.width.px]="110"><strong>{{item.value.channelName}}</strong></td>
                     <td class="text-center" [style.width.px]="80">
                      <input type="checkbox" formControlName="active" (change)= 'item.value.costSchemaId = 0;' [(ngModel)] = "item.value.active">
                     </td>
                     <td>
                       <select style="margin-top: -7px;margin-bottom: -7px;" class="form-control" formControlName="costSchemaId">
                         <option [value]="0" [selected]= '!item.value.active'>None</option>
                         <option [disabled]= '!item.value.active' *ngFor="let sc of schemas" [ngValue]="sc.schemaId">{{sc.schemaName}}</option>
                       </select>
                     </td>
                   </tr>
                 </ng-container>
               </ng-container>
             </tbody>
           </table>
         </div>
       </div> -->
      </div>
      <div class= "row">
        <div class="col-sm-4 col-xs-12" style="background-color: white; margin-top: 20px;  margin-left: 10px;">
          <div class="form-group" [class.has-error]="locationName.invalid && (locationName.dirty || locationName.touched)">
            <label>Location Name <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="locationName" maxlength="100" required>
            <ng-container *ngIf="locationName.invalid && (locationName.dirty || locationName.touched)">
              <span *ngIf="locationName.errors.required" class="help-block">Location name is required.</span>
              <span *ngIf="locationName.errors.pattern" class="help-block">Invalid location name format. Location name should not contain special characters and upper case characters.</span>
              <span *ngIf="locationName.errors.isExistTenant" class="help-block">{{error_message}}</span>
            </ng-container>
          </div>
          <div class="form-group" [class.has-error]="address1.invalid && (address1.dirty || address1.touched)">
            <label>Address1 <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="address1" [placeholder]="''" maxlength="200" (blur)="onPacOutFocus(true)" required #pac>
            <ng-container *ngIf="address1.invalid && (address1.dirty || address1.touched)">
              <span *ngIf="address1.errors.required" class="help-block">Address1 is required.</span>
            </ng-container>
          </div>
          <div class="form-group">
            <label>Address2</label>
            <input type="text" trim="blur" class="form-control" formControlName="address2" maxlength="100">
          </div>
          <div class="form-group" [class.has-error]="city.invalid && (city.dirty || city.touched)">
            <label>City <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="city" />
            <ng-container *ngIf="city.invalid && (city.dirty || city.touched)">
              <span *ngIf="city.errors.required" class="help-block">City is required.</span>
            </ng-container>
          </div>
          <div class="form-group" [class.has-error]="state.invalid && (state.dirty || state.touched)">
            <label>State <span class="text-danger">*</span></label>
            <select class="form-control" formControlName="state" required>
              <option *ngFor="let st of states" [ngValue]="st.stateCode">{{st.stateCode}}</option>
            </select>
            <ng-container *ngIf="state.invalid && (state.dirty || state.touched)">
              <span *ngIf="state.errors.required" class="help-block">State is required.</span>
            </ng-container>

          </div>
          <div class="form-group" [class.has-error]="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
            <label>Postal Code <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="postalCode" mask="00000" required/>
            <ng-container *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
              <span *ngIf="postalCode.errors.required" class="help-block">Postal Code is required.</span>
              <span *ngIf="postalCode.errors.pattern" class="help-block">Invalid postal code format</span>
            </ng-container>
          </div>

          <div class="form-group" [class.has-error]="timeZoneId.invalid && (timeZoneId.dirty || timeZoneId.touched)">
            <label>Timezone <span class="text-danger">*</span></label>
            <select class="form-control" formControlName="timeZoneId" required>
              <option *ngFor="let t of timezones" [ngValue]="t.id">{{t.displayName}}</option>
            </select>
            <ng-container *ngIf="timeZoneId.invalid && (timeZoneId.dirty || timeZoneId.touched)">
              <span *ngIf="timeZoneId.errors.required" class="help-block">Timezone is required.</span>
            </ng-container>
          </div>

          <div class="form-group" [class.has-error]="addressSuggestOptionType.invalid && (addressSuggestOptionType.dirty || addressSuggestOptionType.touched)">
            <label>Address Suggest Otions <span class="text-danger">*</span></label>
            <select class="form-control" formControlName="addressSuggestOptionType" required>
              <option *ngFor="let opt of googleAddressSuggestOptions" [value]="opt.value" [title]="opt.description">{{opt.name}}</option>
            </select>
          </div>
          <div class="form-group" [class.has-error]="addressSuggestRadiusRange.invalid && (addressSuggestRadiusRange.dirty || addressSuggestRadiusRange.touched)">
            <label>Address Suggest Radius (miles) <span class="text-danger">*</span></label>
            <input type="number" min="1" max="999999999" trim="blur" class="form-control" formControlName="addressSuggestRadiusRange" required/>
            <ng-container *ngIf="addressSuggestRadiusRange.invalid && (addressSuggestRadiusRange.dirty || addressSuggestRadiusRange.touched)">
              <span *ngIf="addressSuggestRadiusRange.errors.required" class="help-block">Address Suggest Radius is required.</span>
              <span *ngIf="addressSuggestRadiusRange.errors.min" class="help-block">Address Suggest Radius must be larger than 0.</span>
              <span *ngIf="addressSuggestRadiusRange.errors.pattern" class="help-block">Invalid Address Suggest Radius format</span>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
    <div class="row" style="margin-top: 20px; margin-left: 20px;">
      <button type="button" [disabled]="!form.valid || isLoading" class="btn btn-primary" (click)="save()"> Save </button>
      <button style="margin-left: 10px;" type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    </div>
  </section>
</div>



