/// <reference types="@types/google.maps" />
import { LicenseTypeService } from './../../../services/license-type.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TenantsService } from '../../../services/tenants.service';
import { SettingService } from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { LocationService } from '@app-admin/services/location.service';
import { AddressService } from '@app-admin/services/address.service';
import { ServiceSubscriptionService } from '@app-admin/services/service-subscription.service';
import { InventoryService } from '@app-admin/services/inventory.service';
import * as _ from 'lodash';
declare var $: any;

@Component({
  templateUrl: './details.html',
  styleUrls: ['./details.scss']
})

export class TenantDetailsComponent implements OnInit, AfterViewInit {
  tenantId: any;
  tenantInfo: any;
  tenantDetailForm: FormGroup;
  tenantConfigForm: FormGroup;
  tenantIntegrateForm: FormGroup;
  timezones: any;
  states: any;
  modules: any;
  loading = false;
  licenseTypes = [];
  userCountValid = true;
  old: any;
  oldWorker: any;

  formTemplate: any = [];
  tabGroupIndex: number = 0;
  templateField: any;
  templateType: number = 1;
  templateFields: any = [];
  orderCardFields: any = [];
  orderEditItemOptionFields: any = [];
  indexOrderFieldChanged = -1;
  indexOrderCardChanged = -1;
  indexOrderEditChanged = -1;
  timeoutResetIndexChanged = null;

  allFemplateFields = [
    {
      fieldName: 'type',
      fieldText: 'Type',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 1, 2 ],
    },
    {
      fieldName: 'thickness',
      fieldText: 'Thickness',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 1, 2 ],
    },
    {
      fieldName: 'schist',
      fieldText: 'Schist',
      fieldType: 'checkbox',
      fieldClassName: 'col-4',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'directionality',
      fieldText: 'Directionality',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'finish',
      fieldText: 'Finish',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 1, 2 ],
    },
    {
      fieldName: 'width',
      fieldText: 'Width',
      fieldType: 'number',
      fieldClassName: 'col-4',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'length',
      fieldText: 'Length',
      fieldType: 'number',
      fieldClassName: 'col-4',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'area',
      fieldText: 'Total Area',
      fieldType: 'number',
      fieldClassName: 'col-4',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'unitType',
      fieldText: 'UnitType',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 1, 2, 3 ],
    },
    {
      fieldName: 'supplier',
      fieldText: 'Supplier',
      fieldType: 'supplier',
      fieldClassName: 'col-8',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'supplierCode',
      fieldText: 'Supplier Code',
      fieldType: 'textbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 1 ],
    },
    {
      fieldName: 'unitCost',
      fieldText: 'Unit Cost',
      fieldType: 'currency',
      fieldClassName: 'col-8',
      itemClassIds: [ 1, 2, 3 ],
    },
    {
      fieldName: 'lockCost',
      fieldText: 'Lock Cost',
      fieldType: 'checkbox',
      fieldClassName: 'col-4',
      itemClassIds: [ 1, 2, 3 ],
    },
    {
      fieldName: 'chargeSgna',
      fieldText: 'Charge Sgna',
      fieldType: 'checkbox',
      fieldClassName: 'col-4',
      itemClassIds: [ 1, 3 ],
    },
    {
      fieldName: 'chargeProfit',
      fieldText: 'Charge Profit',
      fieldType: 'checkbox',
      fieldClassName: 'col-4',
      itemClassIds: [ 1, 3 ],
    },
    {
      fieldName: 'procurementType',
      fieldText: 'Procurement Type',
      fieldType: 'procurementType',
      fieldClassName: 'col-12',
      itemClassIds: [ 1, 2 ],
    },
    {
      fieldName: 'images',
      fieldText: 'Images',
      fieldType: 'images',
      fieldClassName: 'col-12',
      itemClassIds: [ 1, 2, 3 ],
    },
    {
      fieldName: 'manufacturer',
      fieldText: 'Manufacturer',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 2 ],
    },
    {
      fieldName: 'typeSize',
      fieldText: 'Type Size',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 2 ],
    },
    {
      fieldName: 'boxQuantity',
      fieldText: 'Box Quantity',
      fieldType: 'number',
      fieldClassName: 'col-8',
      itemClassIds: [ 2 ],
    },
    {
      fieldName: 'itemCategory',
      fieldText: 'Item Category',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 3 ],
    },
    {
      fieldName: 'itemType',
      fieldText: 'Item Type',
      fieldType: 'dropbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 3 ],
    },
    {
      fieldName: 'code',
      fieldText: 'Code',
      fieldType: 'textbox',
      fieldClassName: 'col-8',
      itemClassIds: [ 3 ],
    },
    {
      fieldName: 'chargeOverhead',
      fieldText: 'Charge Overhead',
      fieldType: 'checkbox',
      fieldClassName: 'col-4',
      itemClassIds: [ 3 ],
    },
  ];

  private placeAutocompleteInputAddAddressWidget: google.maps.places.Autocomplete;
  private _placeAutocompleteInputAddAddress: ElementRef;
  @ViewChild("pacAddAddress") set placeAutocompleteInputAddAddress(val){

    this._placeAutocompleteInputAddAddress = val;

    if(this._placeAutocompleteInputAddAddress != null){
      this.placeAutocompleteInputAddAddressWidget = new google.maps.places.Autocomplete(this._placeAutocompleteInputAddAddress.nativeElement, {
        componentRestrictions: { country: ['us'] },
      });

      this.placeAutocompleteInputAddAddressWidget.addListener('place_changed', () => {
        const place = this.placeAutocompleteInputAddAddressWidget.getPlace();

        let addressObj = this.getAddressObj(place);
        this.patchAddressFormAddLocationAddress(addressObj);
      });
    }

  }
  get placeAutocompleteInputAddAddress(){
    return this._placeAutocompleteInputAddAddress;
  }

  private placeAutocompleteInputTenantAddressWidget: google.maps.places.Autocomplete;
  private _placeAutocompleteInputTenantAddress: ElementRef;
  @ViewChild("pacTenantAddress") set placeAutocompleteInputTenantAddress(val){

    this._placeAutocompleteInputTenantAddress = val;

    if(this._placeAutocompleteInputTenantAddress != null){
      this.placeAutocompleteInputTenantAddressWidget = new google.maps.places.Autocomplete(this._placeAutocompleteInputTenantAddress.nativeElement, {
        componentRestrictions: { country: ['us'] },
      });

      this.placeAutocompleteInputTenantAddressWidget.addListener('place_changed', () => {
        const place = this.placeAutocompleteInputTenantAddressWidget.getPlace();

        let addressObj = this.getAddressObj(place);
        this.patchAddressFormAddAddress(addressObj, true);
      });
    }

  }
  get placeAutocompleteInputTenantAddress(){
    return this._placeAutocompleteInputTenantAddress;
  }

  private placeAutocompleteInputWidget: google.maps.places.Autocomplete;
  private _placeAutocompleteInput: ElementRef;
  @ViewChild("pac") set placeAutocompleteInput(val){

    this._placeAutocompleteInput = val;

    if(this._placeAutocompleteInput != null){
      this.placeAutocompleteInputWidget = new google.maps.places.Autocomplete(this._placeAutocompleteInput.nativeElement, {
        componentRestrictions: { country: ['us'] },
      });

      this.placeAutocompleteInputWidget.addListener('place_changed', () => {
        const place = this.placeAutocompleteInputWidget.getPlace();

        let addressObj = this.getAddressObj(place);
        this.patchAddressFormAddAddress(addressObj, false);
      });
    }

  }
  get placeAutocompleteInput(){
    return this._placeAutocompleteInput;
  }



  tenantSubscription: any;
  loadingSubscription = false;
  @ViewChild('modalSubscription') modalSubscription: BsModalComponent;
  // tslint:disable-next-line:variable-name
  modal_title_subscription = '';
  formAddOrEditSubscription: FormGroup;

  // Location
  locations: any;
  loadingLocation = false;
  @ViewChild('modalLocation') modalLocation: BsModalComponent;
  // tslint:disable-next-line:variable-name
  modal_title_location = '';
  formAddOrEditLocation: FormGroup;

  // Modal Delete Location
  @ViewChild('modalDeleteLocation') modalDeleteLocation: BsModalComponent;
  dstLocationId = 0;
  deleteLocationId = 0;
  filterLocations: any[];

  // Modal Reassign Location
  @ViewChild('modalReassignLocation') modalReassignLocation: BsModalComponent;

  // Address
  loadingAddress = false;
  @ViewChild('modalAddress') modalAddress: BsModalComponent;
  formAddAddress: FormGroup;

  serviceTypes;
  corporateLocation: any;
  googleAddressSuggestOptions;

  maxWorkerSchedulePro: number = -1;
  maxWorkerFieldPro: number = -1;
  maxWorkerShopPro: number = -1;

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private tenantService: TenantsService,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private locationService: LocationService,
    private licenseTypeService: LicenseTypeService,
    private serviceSubscriptionService: ServiceSubscriptionService,
    private cdr: ChangeDetectorRef,
    private addressService: AddressService,
    private inventoryService: InventoryService) {

    this.getServiceTypes();
    this.getAddressSuggestOptions();

    activatedRoute.params.subscribe(params => {
      this.tenantId = params.id;
      if (!Number(this.tenantId)) { this.router.navigate(['/not-found']); }
    });

    this.tenantDetailForm = this.formBuilder.group({
      tenantId: [0],
      tenantName: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
      company: ['', [Validators.required]],
      website: ['', [Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
      phone: ['', [Validators.pattern('[0-9]{10}')]],
      timeZoneId: ['', Validators.required],
      active: [''],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
      placeId: [''],
      addressSuggestOptionType: [1, Validators.required],
      addressSuggestRadiusRange: [200, Validators.compose([Validators.required, Validators.min(1), Validators.pattern('(^[0-9]*$)')])],
      locationName: [{ value: '', disabled: true }],
    });

    this.tenantIntegrateForm = this.formBuilder.group({
      tenantId: [0],
      label: [''],
      url: ['', [Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
      active: [false],
      key: ['CONTRACT_RIGHTMENU'],
    })

    this.formAddOrEditSubscription = this.formBuilder.group({
      id: [0],
      tenantId: [0],
      moduleId: ['', Validators.required],
      startDate: [],
      termId: [1],
      amount: [],
      nextBillingDate: [],
      status: [1],
      isEdit: [0]
    });

    this.formAddOrEditLocation = this.formBuilder.group({
      locationId: [0],
      tenantId: [0],
      locationName: ['', Validators.required],
      active: [true],
      // Address
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
      placeId: [''],
      locationAddressType: ['', Validators.required]
    });

    this.formAddAddress = this.formBuilder.group({
      locationId: [0],
      addressType: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
      placeId: [''],
      active: [true]
    });

    this.tenantService.getTenantDetails(this.tenantId).then(res => {
      if (res.status) {
        this.tenantInfo = res.data;
        this.tenantSubscription = this.tenantInfo.subscriptions;
        this.locations = this.tenantInfo.locations;
        this.corporateLocation = this.tenantInfo.corporateLocation;
        const integration = this.tenantInfo.integration;

        this.tenantDetailForm.patchValue({
          tenantId: this.tenantInfo.tenantId,
          tenantName: this.tenantInfo.tenantName,
          company: this.tenantInfo.company,
          website: this.tenantInfo.website,
          phone: this.tenantInfo.phone,
          timeZoneId: this.tenantInfo.timeZoneId,
          active: this.tenantInfo.active,
          address1: this.corporateLocation?.address1,
          address2: this.corporateLocation?.address2,
          city: this.corporateLocation?.city,
          state: this.corporateLocation?.state,
          postalCode: this.corporateLocation?.postalCode,
          placeId: this.corporateLocation?.placeId,
          addressSuggestOptionType: !!this.corporateLocation?.addressSuggestOptionType ? this.corporateLocation?.addressSuggestOptionType : 1,
          addressSuggestRadiusRange: !!this.corporateLocation?.addressSuggestRadiusRange ? this.corporateLocation?.addressSuggestRadiusRange : 200,
          locationName: this.locations.find(x => x.locationId == this.corporateLocation?.locationId)?.locationName,
        });
        if(!!this.corporateLocation?.placeId){
          this.tenantDetailForm.get('city').disable();
          this.tenantDetailForm.get('state').disable();
          this.tenantDetailForm.get('postalCode').disable();
        }

        this.tenantIntegrateForm.patchValue({
          tenantId: this.tenantInfo.tenantId,
          label: integration?.label ?? '',
          url: integration?.url ?? '',
          active: integration?.active ?? false,
          key: 'CONTRACT_RIGHTMENU',
        })

        this.getCountMaxWorker();
      }
    });

    this.loadTemplate();
  }

  ngAfterViewInit(): void {
  }

  getAddressSuggestOptions(){
    this.locationService.getGoogleAddressSuggestOptions().then(res=>{
      this.googleAddressSuggestOptions = res.data;
    });
  }

  getLicenseTypes(moduleId?: number | null) {
    this.licenseTypes = [];
    if (moduleId == null) { return; }
    this.licenseTypeService.getLicenseTypeByModuleTenant(moduleId, this.tenantDetailForm.get('tenantId').value).then(res => {
      if (!res.status) { this.toastr.error('Can\'t get list license types'); return; }
      this.licenseTypes = res.data;
    });
  }

  get tenantName(): FormControl {
    return this.tenantDetailForm.get('tenantName') as FormControl;
  }
  get company(): FormControl {
    return this.tenantDetailForm.get('company') as FormControl;
  }
  get website(): FormControl {
    return this.tenantDetailForm.get('website') as FormControl;
  }
  get phone(): FormControl {
    return this.tenantDetailForm.get('phone') as FormControl;
  }
  get timeZoneId(): FormControl {
    return this.tenantDetailForm.get('timeZoneId') as FormControl;
  }
  get showEdgeImg(): FormControl {
    return this.tenantConfigForm.get('showEdgeImg') as FormControl;
  }

  get url(): FormControl {
    return this.tenantIntegrateForm.get('url') as FormControl;
  }
  get addressSuggestOptionType(): FormControl {
    return this.tenantDetailForm.get('addressSuggestOptionType') as FormControl;
  }
  get addressSuggestRadiusRange(): FormControl {
    return this.tenantDetailForm.get('addressSuggestRadiusRange') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    this.userCountValid = true;

    this.tenantService.getTimeZone().then(response => {
      if (response.status) { this.timezones = response.data; }
      else { this.toastr.error('Can\'t load list timezone'); }
    });

    this.settingService.getListState().then(response => {
      if (response.status) { this.states = response.data; }
      else { this.toastr.error('Can\'t get list state'); }
    });

    this.settingService.getListModule().then(response => {
      if (response.status) { this.modules = response.data; }
      else { this.toastr.error('Can\'t get list module'); }
    });


  }

  /* private getTenantDetails(){
    this.tenantService.getTenantDetails(this.tenantId).then(res => {
      if (res.status) {
        this.tenantInfo = res.data;
        this.tenantSubscription = this.tenantInfo.subscriptions;
        this.locations = this.tenantInfo.locations;
        this.corporateLocation = this.tenantInfo.corporateLocation;
        const integration = this.tenantInfo.integration;

        this.tenantDetailForm.patchValue({
          tenantId: this.tenantInfo.tenantId,
          tenantName: this.tenantInfo.tenantName,
          company: this.tenantInfo.company,
          website: this.tenantInfo.website,
          phone: this.tenantInfo.phone,
          timeZoneId: this.tenantInfo.timeZoneId,
          active: this.tenantInfo.active,
          address1: this.corporateLocation?.address1,
          address2: this.corporateLocation?.address2,
          city: this.corporateLocation?.city,
          state: this.corporateLocation?.state,
          postalCode: this.corporateLocation?.postalCode,
          placeId: this.corporateLocation?.placeId,
        });

        this.tenantIntegrateForm.patchValue({
          tenantId: this.tenantInfo.tenantId,
          label: integration?.label ?? '',
          url: integration?.url ?? '',
          active: integration?.active ?? false,
          key: 'CONTRACT_RIGHTMENU',
        })
      }
    });
  } */

  private loadData() {
    this.tenantService.getTenantDetails(this.tenantId).then(res => {
      if (res.status) {
        this.tenantInfo = res.data;
        this.tenantSubscription = this.tenantInfo.subscriptions;
        this.locations = this.tenantInfo.locations;
        this.getCountMaxWorker();
      }
    });
  }

  private getCountMaxWorker() {
    this.licenseTypeService.getLicenseTypeByModuleTenant(3, this.tenantDetailForm.get('tenantId').value).then(res => {
      if (res.status) this.maxWorkerSchedulePro = _.sumBy(res.data, 'maxWorker');
    });
    this.licenseTypeService.getLicenseTypeByModuleTenant(6, this.tenantDetailForm.get('tenantId').value).then(res => {
      if (res.status) this.maxWorkerShopPro = _.sumBy(res.data, 'maxWorker');
    });
    this.licenseTypeService.getLicenseTypeByModuleTenant(7, this.tenantDetailForm.get('tenantId').value).then(res => {
      if (res.status) this.maxWorkerFieldPro = _.sumBy(res.data, 'maxWorker');
    });
  }

  hasScheduleProLicense(){
    return this.tenantInfo.subscriptions.some(x=>x.moduleId == 3 && x.status == 1);
  }

  updateConfiguration(exit=true) {
    this.loading = true;
    this.tenantService.UpdateTenant(this.tenantInfo).then(res => {
      this.loading = false;
      if (res.status && res.data) {
        this.toastr.success(res.message, 'Tenant has been updated');
        if(exit){
          this.router.navigate(['/tenants']);
        }
      }
      else {
        this.tenantName.setErrors({ isExistTenant: true });
        this.toastr.error(res.message, 'Edit tenant failed');
      }
    });
  }

  updateIsShowEdgeImage(): void {
    this.tenantInfo.isShowEdgeImage = !this.tenantInfo.isShowEdgeImage;
    this.updateConfiguration(false);
  }

  updateIsRunDragAlong(): void {
    this.tenantInfo.isRunDragAlong = !this.tenantInfo.isRunDragAlong;
    this.updateConfiguration(false);
  }

  updateAutoCreateJob(): void {
    this.tenantInfo.autoCreateJob = !this.tenantInfo.autoCreateJob;
    this.updateConfiguration(false);
  }

  updateAutoJobReleaseSwitch(): void {
    this.updateConfiguration(false);
  }

  updateOrderProScheduling(): void {
    this.updateConfiguration(false);
  }

  updateSubmittedOrderStatus():void {
    this.updateConfiguration(false);
  }

  updateServiceClassMode():void {
    this.updateConfiguration(false);
  }

  updateTenantOrderRef(value: string) {
    this.tenantInfo.startOrderReferenceNumber = value;

    this.loading = true;
    this.tenantService.UpdateTenant(this.tenantInfo).then(res => {
      this.loading = false;
      if (!res.status) {
        this.toastr.error(res.message, 'Edit tenant failed');
        return;
      }
      this.toastr.success(res.message, 'Tenant has been updated');
      // this.router.navigate(['/tenants']);
    });
  }

  save() {
    this.loading = true;
    let tenantUpdateInfo = this.tenantDetailForm.value;
    tenantUpdateInfo.corporateLocationId = this.corporateLocation?.locationId ?? 0;
    tenantUpdateInfo.isShowEdgeImage = this.tenantInfo.isShowEdgeImage;
    tenantUpdateInfo.isRunDragAlong = this.tenantInfo.isRunDragAlong;
    tenantUpdateInfo.serviceClassMode = this.tenantInfo.serviceClassMode;
    tenantUpdateInfo.orderProScheduling = this.tenantInfo.orderProScheduling;
    tenantUpdateInfo.startOrderReferenceNumber = this.tenantInfo.startOrderReferenceNumber;
    tenantUpdateInfo.postalCode = this.tenantDetailForm.get('postalCode').value;
    tenantUpdateInfo.city = this.tenantDetailForm.get('city').value;
    tenantUpdateInfo.state = this.tenantDetailForm.get('state').value;


    this.tenantService.UpdateTenant(tenantUpdateInfo)
      .then(response => {
        this.loading = false;
        if (response.status && response.data) {
          this.saveIntegrationForm();
          this.toastr.success(response.message, 'Tenant has been updated');
          this.router.navigate(['/tenants']);
        }
        else {
          this.tenantName.setErrors({ isExistTenant: true });
          this.toastr.error(response.message, 'Edit tenant failed');
        }
      }).catch(() => {
        this.loading = false;
        this.toastr.error('Internal Server Error', 'Error');
      });
  }

  saveIntegrationForm() {
    this.tenantService.UpdateTenantIntegration(this.tenantIntegrateForm.value)
  }

  // For subscription
  get id(): FormControl {
    return this.formAddOrEditSubscription.get('id') as FormControl;
  }
  get moduleId(): FormControl {
    return this.formAddOrEditSubscription.get('moduleId') as FormControl;
  }
  get startDate(): FormControl {
    return this.formAddOrEditSubscription.get('startDate') as FormControl;
  }
  get nextBillingDate(): FormControl {
    return this.formAddOrEditSubscription.get('nextBillingDate') as FormControl;
  }

  getServiceTypes(){
    this.serviceSubscriptionService.getServiceTypes().then(rs=>{
      if(rs.status){
        this.serviceTypes = rs.data;
      }
    });
  }

  openPopupSubscription(item?) {
    this.userCountValid = true;
    if (item) {
      let subscriptionId = item.id;
      let serviceTypeIds = this.tenantInfo.serviceSubscriptions.filter(i => i.subscriptionId == subscriptionId).map(i => i.serviceId);
      serviceTypeIds.forEach(id => {
        let input = document.getElementById('serviceType' + id) as HTMLInputElement;
        input.checked = true;
      });

      this.modal_title_subscription = 'Edit Subscription';
      this.formAddOrEditSubscription.reset();
      this.formAddOrEditSubscription.patchValue({
        id: item.id,
        tenantId: item.tenantId,
        moduleId: item.moduleId,
        startDate: new Date(item.startDate).toLocaleDateString('en-US'),
        termId: item.termId,
        amount: item.amount,
        nextBillingDate: new Date(item.nextBillingDate).toLocaleDateString('en-US'),
        status: item.status
      });
      this.moduleId.disable();
    }
    else {
      this.modal_title_subscription = 'Add Subscription';
      this.formAddOrEditSubscription.reset();
      this.formAddOrEditSubscription.patchValue({
        id: 0,
        tenantId: this.tenantId,
        moduleId: '',
        startDate: '',
        termId: 1,
        amount: null,
        nextBillingDate: '',
        status: 1
      });
      this.moduleId.enable();
    }
    this.modalSubscription.open();

    $('#startDate').datepicker({ autoclose: true }).on('changeDate', (e) => {
      this.startDate.setValue(e.date.toLocaleDateString('en-US'));
    });

    $('#nextBillingDate').datepicker({ autoclose: true }).on('changeDate', (e) => {
      this.nextBillingDate.setValue(e.date.toLocaleDateString('en-US'));
    });
  }

  submitSubscription() {
    if (this.id.value > 0) {
      this.updateSubscription();
    }
    else { this.addSubscription(); }
  }

  getSelectedServiceTypeIds(){
    let selectedServiceTypeIds: number[] = [];
    this.serviceTypes.forEach(st=>{
      let el = document.getElementById('serviceType' + st.serviceId) as HTMLInputElement;
      if(el.checked){
        selectedServiceTypeIds.push(st.serviceId);
      }
    });

    return selectedServiceTypeIds;
  }

  addSubscription() {
    this.loadingSubscription = true;
    let formValue = this.formAddOrEditSubscription.value;
    formValue.serviceTypeIds = this.getSelectedServiceTypeIds();
    this.tenantService.addSubscription({
      ...formValue,
      licenseTypes: this.getLicense()
    }).then(res => {
      this.loadingSubscription = false;
      if (res.status) {
        this.loadData();
        this.toastr.success('Subscription have been added');
        this.modalSubscription.close();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  private getLicense() {
    return this.licenseTypes.map(obj => ({ licenseTypeId: obj.licenseTypeId, maxUser: obj.maxUser, maxWorker: obj.maxWorker }));
  }

  checkUserCountValid(licenseType, oldValue) {
    this.userCountValid = false;
    const license = this.getLicense();
    this.licenseTypeService.checkUserCountValid(this.tenantId, license)
      .then(res => {
        if (!res.status) {
          this.toastr.error(res.message);
          licenseType.maxUser = oldValue;
          return;
        }
        this.userCountValid = true;
      });
  }

  checkWorkerCountValid(licenseType, oldValue) {
    let isValid = true;
    const maxWorker = _.sumBy(this.getLicense(), 'maxWorker');
    if (this.moduleId.value === 3) isValid = maxWorker >= (this.maxWorkerFieldPro + this.maxWorkerShopPro);
    else if (this.moduleId.value === 6) isValid = this.maxWorkerSchedulePro >= (this.maxWorkerFieldPro + maxWorker);
    else if (this.moduleId.value === 7) isValid = this.maxWorkerSchedulePro >= (maxWorker + this.maxWorkerShopPro);

    if (!isValid) {
      licenseType.maxWorker = oldValue;
      this.toastr.error('Field Pro and Shop Pro shall not have more licenses than workers listed in Schedule Pro');
    }
  }


  updateSubscription() {
    this.loadingSubscription = true;
    const license = this.getLicense();
    let formValue = this.formAddOrEditSubscription.value;
    formValue.serviceTypeIds = this.getSelectedServiceTypeIds();
    this.tenantService.updateSubscription(this.id.value, {
      ...formValue,
      licenseTypes: license
    }).then(response => {
      this.loadingSubscription = false;
      if (response.status) {
        this.loadData();
        this.modalSubscription.close();
        this.toastr.success('Subcription has been updated.');
      }
      else {
        this.toastr.error(response.message);
      }
    });
  }

  // For tenant Location
  get locationId(): FormControl {
    return this.formAddOrEditLocation.get('locationId') as FormControl;
  }
  get locationName(): FormControl {
    return this.formAddOrEditLocation.get('locationName') as FormControl;
  }
  get locationAddressType(): FormControl {
    return this.formAddOrEditLocation.get('locationAddressType') as FormControl;
  }
  get activeL(): FormControl {
    return this.formAddOrEditLocation.get('active') as FormControl;
  }
  get address1L(): FormControl {
    return this.formAddOrEditLocation.get('address1') as FormControl;
  }
  get address2L(): FormControl {
    return this.formAddOrEditLocation.get('address2') as FormControl;
  }
  get cityL(): FormControl {
    return this.formAddOrEditLocation.get('city') as FormControl;
  }
  get stateL(): FormControl {
    return this.formAddOrEditLocation.get('state') as FormControl;
  }
  get postalCodeL(): FormControl {
    return this.formAddOrEditLocation.get('postalCode') as FormControl;
  }
  get placeIdL(): FormControl {
    return this.formAddOrEditLocation.get('placeId') as FormControl;
  }

  openPopupLocation(item?) {
    if (item) {
      this.modal_title_location = 'Edit Location';
      this.formAddOrEditLocation.reset();
      this.formAddOrEditLocation.patchValue({
        locationId: item.locationId,
        tenantId: item.tenantId || this.tenantId,
        locationName: item.locationName,
        active: item.active,
        locationAddressType: item.addressType
      });

      this.clearValidators();
    }
    else {
      this.modal_title_location = 'Add Location';
      this.formAddOrEditLocation.reset();
      this.formAddOrEditLocation.patchValue({
        locationId: 0,
        tenantId: this.tenantId,
        locationName: '',
        active: true,
        locationAddressType: 'Service'
      });
      this.onChangeAddressType(this.formAddOrEditLocation.get('locationAddressType'));
      this.setValidators();
    }
    this.modalLocation.open();
  }

  private setValidators() {
    this.address1L.setValidators([Validators.required]);
    this.address1L.updateValueAndValidity();
    this.cityL.setValidators([Validators.required]);
    this.cityL.updateValueAndValidity();
    this.stateL.setValidators([Validators.required]);
    this.stateL.updateValueAndValidity();
    this.postalCodeL.setValidators([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')]);
    this.postalCodeL.updateValueAndValidity();
  }

  private clearValidators() {
    this.address1L.clearValidators();
    this.address1L.updateValueAndValidity();
    this.cityL.clearValidators();
    this.cityL.updateValueAndValidity();
    this.stateL.clearValidators();
    this.stateL.updateValueAndValidity();
    this.postalCodeL.clearValidators();
    this.postalCodeL.updateValueAndValidity();
  }

  submitLocation() {
    if (this.locationId.value > 0) {
      this.updateLocation();
    }
    else {
      this.addLocation();
    }
  }

  private addLocation() {
    this.loadingLocation = true;
    // let formData = this.formAddOrEditLocation.value;
    // if(!!formData.placeId){
    //   formData.postalCode = this.formAddOrEditLocation.get('postalCode').value;
    //   formData.city = this.formAddOrEditLocation.get('city').value;
    //   formData.state = this.formAddOrEditLocation.get('state').value;
    // }
    this.locationService.addLocation(this.tenantId, this.formAddOrEditLocation.getRawValue()).then(res => {
      this.loadingLocation = false;
      if (res.status) {
        this.loadData();
        this.modalLocation.close();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  private updateLocationActive(item: any): void {
    let oldVal = item.active;
    let newVal = !oldVal;

    if (!newVal) {
      // each tenant must have at least one active location.
      const existedActive = this.locations.filter(x => x.locationId !== item.locationId && x.active);
      if (existedActive.length === 0) {
        return;
      }
    }

    let patchVal = {
      active: newVal,
    };
    this.formAddOrEditLocation.patchValue(patchVal);

    this.locationService.updateLocationAndAddress(item.locationId, this.tenantId, patchVal).then(response => {
      if (response.status) {
        // Update local data
        item.active = newVal;
        this.toastr.success(response.message);
      } else {
        // Update local data
        item.active = oldVal;
        this.toastr.error(response.message);
      }
    });
  }

  private updateLocation(): void {
    this.loadingLocation = true;
    // let formData = this.formAddOrEditLocation.value;
    // if(!!formData.placeId){
    //   formData.postalCode = this.formAddOrEditLocation.get('postalCode').value;
    //   formData.city = this.formAddOrEditLocation.get('city').value;
    //   formData.state = this.formAddOrEditLocation.get('state').value;
    // }
    let updateValue = this.formAddOrEditLocation.getRawValue();
    updateValue.addressType = updateValue.locationAddressType;
    this.locationService.updateLocationAndAddress(this.locationId.value, this.tenantId, updateValue).then(response => {
      this.loadingLocation = false;
      if (response.status) {
        this.loadData();
        this.modalLocation.close();
        this.toastr.success(response.message);

        if (updateValue.addressType === 'Corporate') {
          this.tenantDetailForm.patchValue({
            locationName: updateValue.locationName,
          });
        }
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  confirmDeleteLocation(locationId: number) {
    this.deleteLocationId = locationId;
    this.modalDeleteLocation.open();
    // this.filterLocations = this.locations.filter(x => x.locationId !== this.deleteLocationId && x.active);
  }

  deleteLocation() {
    this.locationService.deleteLocation(this.deleteLocationId, this.tenantId, 0).then(response => {
      this.loadingLocation = false;
      if (response.status) {
        this.loadData();
        this.toastr.success('Location has been deleted');

        // Clean Delete Modal
        this.deleteLocationId = 0;
        // this.dstLocationId = 0;
        // this.filterLocations = [];
        this.modalDeleteLocation.close();
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  countActiveLocations() {
    return this.locations.filter(x => x.active).length;
  }

  // Address
  get addressType(): FormControl {
    return this.formAddAddress.get('addressType') as FormControl;
  }
  get address1(): FormControl {
    return this.formAddAddress.get('address1') as FormControl;
  }
  get address2(): FormControl {
    return this.formAddAddress.get('address2') as FormControl;
  }
  get city(): FormControl {
    return this.formAddAddress.get('city') as FormControl;
  }
  get state(): FormControl {
    return this.formAddAddress.get('state') as FormControl;
  }
  get postalCode(): FormControl {
    return this.formAddAddress.get('postalCode') as FormControl;
  }
  get placeId(): FormControl {
    return this.formAddAddress.get('placeId') as FormControl;
  }

  openPopupAddress(locationId: number) {
    this.formAddAddress.reset();
    this.formAddAddress.patchValue({
      locationId,
      addressType: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      active: true,
    });
    this.modalAddress.open();
  }

  submitAddress() {
    this.addAddress();
  }

  private addAddress() {
    this.loadingAddress = true;
    this.addressService.addAddress(this.formAddAddress.getRawValue()).then(res => {
      this.loadingAddress = false;
      if (res.status) {
        this.loadData();
        this.modalAddress.close();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  changeActive(item: any): void {
    let oldVal = item.active;
    let newVal = !oldVal;

    if (newVal) {
      this.updateLocationActive(item);
      return;
    }
    this.locationService.checkDeleteLocation(item.locationId, this.tenantId).then(res => {
      if (res.status) {
        this.updateLocationActive(item);
      } else {
        // Open modal reassign location
        this.deleteLocationId = item.locationId;
        this.filterLocations = this.locations.filter(x => x.locationId !== this.deleteLocationId && x.active);
        this.modalReassignLocation.open();
      }
    });
  }

  reassignLocation(): void {
    this.locationService.updateLocationAndAddress(this.deleteLocationId, this.tenantId, {
      active: false,
      newAssignLocationId: this.dstLocationId,
    }).then(res => {
      if (res.status) {
        this.toastr.success(res.message);

        // Update local data
        let found = this.locations.find(x => x.locationId == this.deleteLocationId);
        if (found) {
          found.active = false;
        }

        // Clean Delete Modal
        this.deleteLocationId = 0;
        this.dstLocationId = 0;
        this.filterLocations = [];
        this.modalReassignLocation.close();
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  getAddressObj(place: google.maps.places.PlaceResult) {
    let streetNumber = place.address_components.find(x => x.types.find(t => ['street_number'].includes(t)));
    let route = place.address_components.find(x => x.types.find(t => ['route'].includes(t)));
    let city = place.address_components.find(x => x.types.find(t => ['locality'].includes(t)));
    if (!city) {
      city = place.address_components.find(x => x.types.find(t => ['sublocality_level_1'].includes(t)));
    }
    let state = place.address_components.find(x => x.types.find(t => ['administrative_area_level_1'].includes(t)));
    let postalCode = place.address_components.find(x => x.types.find(t => ['postal_code'].includes(t)));

    return {
      address: `${streetNumber?.short_name || ''} ${route?.short_name || ''}`,
      city: city?.short_name || '',
      stateAbbreviation: state?.short_name || '',
      postalCode: postalCode?.short_name || '',

      placeId: place.place_id,
      origin: '',
      destination: place.formatted_address,
      orgLat: 0,
      orgLng: 0,
      dstLat: place.geometry.location.lat(),
      dstLng: place.geometry.location.lng(),
      duration: 0,
      durationInHour: 0,
      distance: 0,
      distanceInMile: 0,
    };
  }

  patchAddressForm(addressObj) {
    this.address1L.setValue(addressObj.address);
    this.cityL.setValue(addressObj.city);
    this.stateL.setValue(addressObj.stateAbbreviation);
    this.postalCodeL.setValue(addressObj.postalCode);

    this.placeAutocompleteInput.nativeElement.blur();
    this.onPacOutFocus(false);
    this.placeIdL.setValue(addressObj.placeId);

    this.cdr.detectChanges();
  }

  onPacOutFocus(isCustomAddress){
    if(isCustomAddress){
      this.placeIdL.setValue(null);
      this.cityL.setValue(null);
      this.stateL.setValue(null);
      this.postalCodeL.setValue(null);
    }else{
      this.cityL.disable();
      this.stateL.disable();
      this.postalCodeL.disable();
    }
  }

  // START: Map for Add Address
  private patchAddressFormAddAddress(addressObj, isTenantAddress = false): void {
    if(isTenantAddress){
      this.tenantDetailForm.get('address1').setValue(addressObj.address);
      this.tenantDetailForm.get('city').setValue(addressObj.city);
      this.tenantDetailForm.get('state').setValue(addressObj.stateAbbreviation);
      this.tenantDetailForm.get('postalCode').setValue(addressObj.postalCode);

      this.placeAutocompleteInputTenantAddress.nativeElement.blur();
      this.onPacOutFocusAddAddress(false, isTenantAddress);
      this.tenantDetailForm.get('placeId').setValue(addressObj.placeId);
    }else{
      this.formAddOrEditLocation.get('address1').setValue(addressObj.address);
      this.formAddOrEditLocation.get('city').setValue(addressObj.city);
      this.formAddOrEditLocation.get('state').setValue(addressObj.stateAbbreviation);
      this.formAddOrEditLocation.get('postalCode').setValue(addressObj.postalCode);

      this.placeAutocompleteInputAddAddress.nativeElement.blur();
      this.onPacOutFocusAddAddress(false);
      this.placeIdL.setValue(addressObj.placeId);
    }
    this.cdr.detectChanges();
  }

  private patchAddressFormAddLocationAddress(addressObj): void {
    this.formAddAddress.get('address1').setValue(addressObj.address);
    this.formAddAddress.get('city').setValue(addressObj.city);
    this.formAddAddress.get('state').setValue(addressObj.stateAbbreviation);
    this.formAddAddress.get('postalCode').setValue(addressObj.postalCode);

    this.placeAutocompleteInputAddAddress.nativeElement.blur();

    this.formAddAddress.get('city').disable();
    this.formAddAddress.get('state').disable();
    this.formAddAddress.get('postalCode').disable();

    this.formAddAddress.get('placeId').setValue(addressObj.placeId);
    this.cdr.detectChanges();
  }

  onPacOutFocusAddAddress(isCustomAddress, isTenantAddress = false): void {
    let city;
    let state;
    let postalCode;
    let placeId;
    if(isTenantAddress){
      city = this.tenantDetailForm.get('city');
      state = this.tenantDetailForm.get('state');
      postalCode = this.tenantDetailForm.get('postalCode');
      placeId = this.tenantDetailForm.get('placeId');
    }else{
      city = this.formAddOrEditLocation.get('city');
      state = this.formAddOrEditLocation.get('state');
      postalCode = this.formAddOrEditLocation.get('postalCode');
      placeId = this.formAddOrEditLocation.get('placeId');
    }

    if(isCustomAddress){
      placeId.setValue(null);
      city.setValue(null);
      state.setValue(null);
      postalCode.setValue(null);
    }else{
      city.disable();
      state.disable();
      postalCode.disable();
    }
  }
  // END: Map for Add Address

  isShowCorporate(){
    let locationId = this.formAddOrEditLocation.get('locationId').value;
    if(!!locationId){
      //edit location popup
      let editingLocation = this.locations.find(i=>locationId == i.locationId);
      return editingLocation.addressType == 'Corporate';
    }else{
      //add location popup
      return this.locations?.find(i=>i.addressType == 'Corporate') == null;
    }
  }

  onChangeAddressType(e){
    if(e.value == 'Corporate'){
      let opts: google.maps.places.AutocompleteOptions = {
        bounds: null,
        strictBounds: null,
      };
      this.placeAutocompleteInputWidget.setOptions(opts);
      this.placeAutocompleteInputAddAddressWidget.setOptions(opts);
    }else{
      this.getCorporateBounds().then(rs=>{
        let bounds = rs;
        let opts: google.maps.places.AutocompleteOptions = {
          bounds: bounds,
          strictBounds: this.corporateLocation == null ? null : (this.corporateLocation.addressSuggestOptionType == 2),
        };

        this.placeAutocompleteInputWidget.setOptions(opts);
        this.placeAutocompleteInputAddAddressWidget.setOptions(opts);
      })
    }
  }

  getCorporateBounds(): Promise<any>{
      if(!!this.corporateLocation){
        let geoService = new google.maps.Geocoder;
        return geoService.geocode({placeId: this.corporateLocation.placeId}).then(({ results })=>{
          let result = results[0];
          let center = new google.maps.LatLng(result.geometry.location.lat(), result.geometry.location.lng());
          let radiusInKm = this.corporateLocation.addressSuggestRadiusRange * 1.609;
          let bounds = {
            north: center.lat() + (radiusInKm / 100),
            south: center.lat() - (radiusInKm / 100),
            east: center.lng() + (radiusInKm / 100),
            west: center.lng() - (radiusInKm / 100)
          }
          return bounds;
        });
      }else{
        return new Promise((resolve)=>{
          resolve(null);
        })
      }
  }

  isDisableDetailFormSave(){
    return /* !!!this.tenantDetailForm.get('placeId').value || */ this.tenantDetailForm.invalid || this.loading;
  }

  loadTemplate() {
    const _this = this;
    this.templateField = null;
    this.tabGroupIndex = 0;
    this.inventoryService.getConfigTemplate(this.tenantId, this.templateType).then(res => {
      if (res.status) {
        this.formTemplate = [];
        _.forEach(_.groupBy(_.orderBy(res.data, 'fieldIndex'), 'groupIndex'), function (x) {
          _this.formTemplate.push({
            panelTitle: x[0].groupText,
            fields: x,
          });
        });
        this.loadOrderCardFields();
        this.loadEditItemOptionFields();
      } else this.toastr.error(res.message);
    });
    this.templateFields = _.filter(this.allFemplateFields, function(o) {
      return _.indexOf(o.itemClassIds, Number(_this.templateType)) !== -1;
    });
  }

  loadOrderCardFields() {
    this.orderCardFields = _.orderBy(_.filter(this.getFields(), 'fieldShowOnCard'), 'fieldCardIndex');
  }

  loadEditItemOptionFields() {
    this.orderEditItemOptionFields = _.orderBy(_.filter(this.getFields(), function(o) {
      return o.fieldType != 'images';
    }), 'fieldEditIndex');
  }

  addFieldGroup() {
    this.formTemplate.push(
      {
        panelTitle: 'Field Group Name ' + (this.formTemplate.length + 1),
        fields: []
      }
    );
  }

  swapGroup(index, newIndex) {
    let temp = this.formTemplate[index];
    this.formTemplate[index] = this.formTemplate[newIndex];
    this.formTemplate[newIndex] = temp;
    this.tabGroupIndex = newIndex;
  }

  removeGroup(index) {
    this.formTemplate.splice(index, 1);
    this.loadOrderCardFields();
    this.loadEditItemOptionFields();
  }

  moveGroupUp(index) {
    this.swapGroup(index, index - 1);
  }

  moveGroupDown(index) {
    this.swapGroup(index, index + 1);
  }

  swap(indexParent, index, newIndex) {
    let temp = this.formTemplate[indexParent].fields[index];
    this.formTemplate[indexParent].fields[index] = this.formTemplate[indexParent].fields[newIndex];
    this.formTemplate[indexParent].fields[newIndex] = temp;
  }

  moveUp(indexParent, index) {
    this.indexOrderFieldChanged = index - 1;
    this.swap(indexParent, index, this.indexOrderFieldChanged);
    this.resetIndexOrderChanged();
  }

  moveDown(indexParent, index) {
    this.indexOrderFieldChanged = index + 1;
    this.swap(indexParent, index, this.indexOrderFieldChanged);
    this.resetIndexOrderChanged();
  }

  removeField(indexParent, index) {
    this.formTemplate[indexParent].fields.splice(index, 1);
    this.loadOrderCardFields();
    this.loadEditItemOptionFields();
  }

  swapCard(index, newIndex) {
    let temp = this.orderCardFields[index];
    this.orderCardFields[index] = this.orderCardFields[newIndex];
    this.orderCardFields[newIndex] = temp;
  }

  moveCardLeft(index) {
    this.indexOrderCardChanged = index - 1;
    this.swapCard(index, this.indexOrderCardChanged);
    this.resetIndexOrderChanged();
  }

  moveCardUp(index) {
    this.indexOrderCardChanged = index - 3;
    this.swapCard(index, this.indexOrderCardChanged);
    this.resetIndexOrderChanged();
  }

  moveCardDown(index) {
    this.indexOrderCardChanged = index + 3;
    this.swapCard(index, this.indexOrderCardChanged);
    this.resetIndexOrderChanged();
  }

  moveCardRight(index) {
    this.indexOrderCardChanged = index + 1;
    this.swapCard(index, this.indexOrderCardChanged);
    this.resetIndexOrderChanged();
  }

  swapEditItemOption(index, newIndex) {
    let temp = this.orderEditItemOptionFields[index];
    this.orderEditItemOptionFields[index] = this.orderEditItemOptionFields[newIndex];
    this.orderEditItemOptionFields[newIndex] = temp;
  }

  moveEditItemOptionLeft(index) {
    this.indexOrderEditChanged = index - 1;
    this.swapEditItemOption(index, this.indexOrderEditChanged);
    this.resetIndexOrderChanged();
  }

  moveEditItemOptionUp(index) {
    this.indexOrderEditChanged = index - 3;
    this.swapEditItemOption(index, this.indexOrderEditChanged);
    this.resetIndexOrderChanged();
  }

  moveEditItemOptionDown(index) {
    this.indexOrderEditChanged = index + 3;
    this.swapEditItemOption(index, this.indexOrderEditChanged);
    this.resetIndexOrderChanged();
  }

  moveEditItemOptionRight(index) {
    this.indexOrderEditChanged = index + 1;
    this.swapEditItemOption(index, this.indexOrderEditChanged);
    this.resetIndexOrderChanged();
  }

  resetIndexOrderChanged() {
    if (!!this.timeoutResetIndexChanged) clearTimeout(this.timeoutResetIndexChanged);
    this.timeoutResetIndexChanged = setTimeout(() => {
      this.indexOrderFieldChanged = -1;
      this.indexOrderCardChanged = -1;
      this.indexOrderEditChanged = -1;
    }, 3000);
  }

  changeFieldShowOnCard() {
    this.loadOrderCardFields();
    this.loadEditItemOptionFields();
  }

  addField(index) {
    if (_.indexOf(_.flatMap(_.map(this.formTemplate, x => x.fields.map(x => x.fieldName))), this.templateField.fieldName) !== -1) {
      this.toastr.error('This field already exists.');
      return;
    }
    this.formTemplate[index].fields.push({
      id: 0,
      fieldClassName: this.templateField.fieldClassName,
      fieldIndex: -1,
      fieldEditIndex: -1,
      fieldCardIndex: -1,
      fieldIsRequired: false,
      fieldName: this.templateField.fieldName,
      fieldShowOnCard: false,
      fieldText: this.templateField.fieldText,
      fieldType: this.templateField.fieldType,
      groupIndex: -1,
      groupText: 'xxx',
      itemClassId: this.templateType,
      tenantId: Number(this.tenantId),
    });
    this.loadOrderCardFields();
    this.loadEditItemOptionFields();
  }

  saveTemplate() {
    this.inventoryService.updateConfigTemplate(this.tenantId, this.templateType, this.getFields(true)).then(res => {
      if (res.status) {
        this.toastr.success('Updated');
        this.loadTemplate();
      }
      else this.toastr.error(res.message);
    });
  }

  getFields(isForSave: boolean = false) {
    const _this = this;
    let fields = [];
    let fieldIndex = 0;
    _.forEach(this.formTemplate, function (x, xIndex) {
      _.forEach(x.fields, function (xx) {
        let fieldEditIndex = isForSave ? _.indexOf(_.map(_this.orderEditItemOptionFields, 'fieldName'), xx.fieldName) : -1;
        let fieldCardIndex = isForSave ? _.indexOf(_.map(_this.orderCardFields, 'fieldName'), xx.fieldName) : -1;
        fields.push({
          id: xx.id,
          fieldClassName: xx.fieldClassName,
          fieldIndex: fieldIndex,
          fieldEditIndex: isForSave ? (fieldEditIndex === -1 ? null : fieldEditIndex) : xx.fieldEditIndex,
          fieldCardIndex: isForSave ? (fieldCardIndex === -1 ? null : fieldCardIndex) : xx.fieldCardIndex,
          fieldIsRequired: xx.fieldIsRequired,
          fieldName: xx.fieldName,
          fieldShowOnCard: xx.fieldShowOnCard,
          fieldText: xx.fieldText,
          fieldType: xx.fieldType,
          groupIndex: xIndex,
          groupText: x.panelTitle,
          itemClassId: xx.itemClassId,
          tenantId: xx.tenantId,
        });
        fieldIndex += 1;
      });
    });
    return fields;
  }

  updatePurchaseOrderReference(): void {
    this.updateConfiguration(false);
  }

  updateJobAccountingReference(): void {
    this.updateConfiguration(false);
  }
}
