<div class="row">
  <div class="col-xl-12 pull-right">
    <div class="form-group">
      <button (click)="addChannel()" [disabled]="loading || (enabledEdit && (dataForm.dirty || tenantChannelsService.isDirty() || tileTenantChannelsService.isDirty()))" type="button" class="btn btn-primary pull-right" style="margin-right: 10px;">
        <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
        <strong *ngIf="!loading">+ </strong> Add
      </button>
    </div>
  </div>
  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <!-- <th [style.width.px]="30"></th> -->
              <th>Sales Channel</th>
              <th [style.width.px]="70" class="text-center">Active</th>
              <th [style.width.px]="70" class="text-center">Standard</th>
              <!-- <th>Default Slab Price</th>
              <th>Default Slab Cost</th>
              <th>Default Tile Price</th>
              <th>Default Tile Cost</th> -->
              <th [style.width.px]="70" class="text-center">Sales</th>
              <th [style.width.px]="85" class="text-center">Production</th>
              <th [style.width.px]="150">Last Updated</th>
              <th [style.width.px]="150">Modified By</th>
              <th [style.width.px]="100"></th>
              <th [style.width.px]="80"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <!-- <td [style.width.px]="30">
                    <a
                    data-toggle="collapse" href="#collapse_{{item.value.businessUnitId}}_{{item.value.id}}" aria-expanded="false" aria-controls="collapse_{{item.value.businessUnitId}}_{{item.value.id}}">
                      <i class="fa fa-arrows-v"></i>
                    </a>
                  </td> -->
                  <td>
                    <input type="text" class="form-control" formControlName="channelNameAlias">
                  </td>
                  <td [style.width.px]="70" class="text-center">
                    <input #check type="checkbox" (change)="changeActive(item)" formControlName="active">
                  </td>
                  <td [style.width.px]="70" class="text-center">
                    <input type="checkbox" formControlName="standard">
                  </td>
                  <!-- <td> -->
                    <!-- <select class="form-control" formControlName="schemaPriceId"  >
                      <option *ngFor="let c of priceSchemas" [disabled] = '!check.checked' [value]="c.schemaPriceId">{{ c.schemaPriceName }}</option>
                    </select> -->
                    <!-- {{ item.value.schemaPriceName }}
                  </td>
                  <td>
                    {{ item.value.costSchemaName }}
                  </td>
                  <td>
                    {{ item.value.tilePriceSchemaName }}
                  </td>
                  <td>
                    {{ item.value.tileCostSchemaName }}
                  </td> -->
                  <td [style.width.px]="70" class="text-center">
                    <input type="checkbox" formControlName="isSaleModule">
                  </td>
                  <td [style.width.px]="85" class="text-center">
                    <input type="checkbox" formControlName="isProductionModule">
                  </td>
                  <td [style.width.px]="150">{{ item.value.updatedOn | date:'MM/dd/yyyy h:mm a' }}</td>
                  <td [style.width.px]="150">{{ item.value.updatedBy }}</td>
                  <td [style.width.px]="100">
                    <button [disabled]="!enabledEdit" (click)="passingDataToModal(item.value, false)" type="button" class="btn btn-primary" data-toggle="modal"
                      data-target="#reassignChannelModal">
                      Re-assign
                    </button>
                  </td>
                  <td [style.width.px]="80" class="text-center">
                    <a
                      *ngIf="!item.value.standard && enabledEdit"
                      href="javascript:;"
                      class="fa fa-lg fa-trash-o text-danger"
                      title="Delete"
                      (click)="clickDelete(item.value)">
                    </a>
                    <!-- <a
                      *ngIf="!item.value.standard && enabledEdit"
                      href="#"
                      class="fa fa-lg fa-trash-o text-danger"
                      title="Delete"
                      (click)="clickDelete(item.value)"
                      data-toggle="modal"
                      data-target="#reassignChannelModal">
                    </a> -->
                    <!-- (click)="deleteChannel(item.value.channelId)" -->
                  </td>
                </tr>
                <!-- <assigned-cost-schemas [currentRow]="item.value" [enabledEdit]="enabledEdit"></assigned-cost-schemas> -->
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<!-- Trigger when Change Active -->
<bs-modal #modal data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <label *ngIf="!ok">Are you sure? This requires assigning open bids to another channel.</label>
    <div *ngIf="ok">
      <label class="row col-sm-12">Choose another channel that open bids will use going foward.</label>
      <select class="row col-sm-12" style="width: 300px; height: 40px; margin-left: 10px;" (change)= 'btn.focus()' [(ngModel)] = 'dstChannelId'>
        <option [value]="0">None</option>
        <option *ngFor="let c of dataSelect" [value]="c.channelId">{{ c.channelNameAlias }}</option>
      </select>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button #btn class="btn btn-primary" [disabled]= "ok && dstChannelId == 0" (click) = "confirm();" autofocus>Yes</button>
    <button class="btn btn-secondary" (click) = "confirm(true);">No</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAddChannel>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Add New Channel</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="addChannelForm">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label>Channel Name
              <span class="text-danger">*</span>
            </label>
            <input type="text" trim="blur" class="form-control" formControlName="channelName" maxlength="50" required>
          </div>
        </div>
        <!-- <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label>Default Price Schema
              <span class="text-danger">*</span>
            </label>
            <select formControlName="priceSchemaId" class="form-control">
              <option *ngFor="let t of priceSchemas" [ngValue]="t.schemaPriceId">{{t.schemaPriceName}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label>Cost Schema
              <span class="text-danger">*</span>
            </label>
            <select formControlName="costSchemaId" class="form-control">
              <option *ngFor="let t of costSchemas" [ngValue]="t.costSchemaId">{{t.schemaName}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label>Default Material Group
              <span class="text-danger">*</span>
            </label>
            <select formControlName="slabGroupId" class="form-control">
              <option [ngValue]="-1">None</option>
              <option *ngFor="let x of slabGroups" [ngValue]="x.slabGroupId">{{x.groupName}}</option>
            </select>
          </div>
        </div> -->
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" style="margin-top: 30px">
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="active" /> Active
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAddChannel.close()">Cancel</button>
    <button type="button" [disabled]="addChannelForm.invalid || addChannelForm.disabled" class="btn btn-primary"
      (click)="saveChannel()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure delete this channel?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmDelete.close()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDeleteChannel()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmApplyChange>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? This will impact all open bids and production sales orders. Be certain before executing this action.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmApplyChange.close()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="save(false)">Submit</button>
  </bs-modal-footer>
</bs-modal>

<!-- <assigned-cost-schemas-modal></assigned-cost-schemas-modal> -->

<reassign-channel-modal></reassign-channel-modal>
