export const environment = {
  dev: false,
  qa: false,
  qa2: true,
  slot: false,
  staging: false,
  production: false,
  apiURL: '/api/',
  rootURL: 'https://api.qxmqa.com/',
  linkDPT: "http://westus-cpqdptserviceqa2.azurewebsites.net/api",
  activeReportServer: "http://13.64.148.43:8080/api/",
  linkAPIToolCompare: "http://westus-cpqbackendtoolcompare.azurewebsites.net/api/"
};
