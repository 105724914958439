import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as _ from 'lodash';
import { TenantEdgeConfigService } from '@app-admin/services/tenant-edge-config.service';

@Component({
  selector: 'tab-edge',
  templateUrl: './edge.html',
  styleUrls: ['./edge.scss']
})

export class EdgeComponent implements OnInit {
  @Input() tenantId: number;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirm2') modalConfirm2: BsModalComponent;
  loading = false;
  data: any[] = [];
  dataSorted : any[] = [];
  unassignData: any[] = [];
  dataFilter: any[] = [];
  dataForm: FormGroup;
  dataFormInit: FormGroup;
  // formAdd: FormGroup;

  edgeTypeId: number = 0;
  replaceId: number = 0;
  sortOption : number = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService
    , private tenantEdgeConfigService: TenantEdgeConfigService) {

  }

  ngOnInit() {
    this.loadData();
    // this.formAdd = this.fb.group({
    //   tenantId: [this.tenantId],
    //   edgeType: ['', Validators.required],
    //   alias: [''],
    //   helperText: [''],
    //   straight: [true],
    //   radius: [true],
    //   undermount: [true],
    //   onsite: [false],
    //   frequentlyUsed: [false],
    //   applyHeight: [false],
    // });
  }

  // get edgeType(): FormControl {
  //   return this.formAdd.get('edgeType') as FormControl;
  // }

  // getErrorEdgeType() {
  //   return this.edgeType.hasError('required') ? 'Edge name cannot be empty' : '';
  // }

  private loadData(): void {
    this.loading = true;
    this.settingService.getEdgeTypes(this.tenantId).then(response => {
      this.loading = false;
      if (!response.status) {
        this.toast.error('Load data failed');
        return;
      }
      this.data = response.data;
      this.dataSorted = JSON.parse(JSON.stringify(response.data));
      this.dataSorted.sort((a, b) => a.edgeType.localeCompare(b.edgeType))
      this.filterData();
      this.calcHeightTable.emit();
    });

    this.tenantEdgeConfigService.GetUnassignEdges(this.tenantId).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.sortOption == 0 ? this.data : this.dataSorted;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    this.dataFormInit = Object.assign({}, this.dataForm);
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      edgeId: e.edgeId,
      edgeType: [e.edgeType, Validators.required],
      alias: e.alias,
      helperText: e.helperText,
      edgeColor: e.edgeColor,
      straight: e.straight,
      radius: e.radius,
      undermount: e.undermount,
      onsite: e.onsite,
      frequentlyUsed: e.frequentlyUsed,
      applyHeight: e.applyHeight,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  // addEdge() {
  //   this.edgeType.markAsDirty();
  //   this.edgeType.markAsTouched();
  //   if (this.formAdd.valid) {
  //     this.loading = true;
  //     this.formAdd.disable();
  //     this.settingService.addEdgeTypes(this.formAdd.value).then(response => {
  //       if (response.status) {
  //         this.formAdd.patchValue({
  //           tenantId: this.tenantId,
  //           edgeType: '',
  //           alias: '',
  //           helperText: '',
  //           straight: true,
  //           radius: true,
  //           undermount: true,
  //           onsite: false,
  //           frequentlyUsed: false,
  //           applyHeight: false,
  //         });
  //         this.formAdd.markAsPristine();
  //         this.formAdd.markAsUntouched();
  //         this.toast.success('New Edge has been added');
  //         this.reloadStatusBatchVersions.emit();
  //         this.loadData();
  //       }
  //       else {
  //         if (response.data == 0) {
  //           this.toast.error('Edge was not added successfully. Be sure to check the required fields to ensure you\'ve provided the proper information. Additionally, each edge must be unique.');
  //         }
  //         else {
  //           this.toast.error('The Edge is added fail');
  //         }
  //         this.loading = false;
  //       }
  //       this.formAdd.enable();
  //     });
  //   }
  // }

  // deleteEdge(edgeTypeId) {
  //   this.modalConfirm.open();
  //   this.edgeTypeId = edgeTypeId;
  // }

  // submitDelete() {
  //   this.loading = true;
  //   this.modalConfirm.close();
  //   this.settingService.deleteEdgeType(this.edgeTypeId).then(response => {
  //     if (response.status) {
  //       this.toast.success('Edge has been removed');
  //       this.loading = true;
  //       this.loadData();
  //       // this.settingService.setBidsIsRefesh(this.tenantId);
  //     }
  //     else this.toast.error(response.message);
  //     this.loading = false;
  //   });
  // }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.edgeId == e.edgeId);
    item.edgeType = e.edgeType;
    item.alias = e.alias;
    item.helperText = e.helperText;
    item.edgeColor = e.edgeColor;
    item.straight = e.straight;
    item.radius = e.radius;
    item.undermount = e.undermount;
    item.onsite = e.onsite;
    item.frequentlyUsed = e.frequentlyUsed;
    item.applyHeight = e.applyHeight;
    return {
      edgeId: e.edgeId,
      edgeType: e.edgeType,
      alias: e.alias,
      helperText: e.helperText,
      edgeColor: e.edgeColor,
      straight: e.straight,
      radius: e.radius,
      undermount: e.undermount,
      onsite: e.onsite,
      frequentlyUsed: e.frequentlyUsed,
      applyHeight: e.applyHeight,
      isGlobal: false,
      tenantId: this.tenantId
    };
  }

  save(): void {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (this.dataForm.invalid) {
      this.toast.error(' Edge cannot be empty and must be unique');
      isValid = false;
    }
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateEdges(dataUpdate).then(response => {
        if (response.status) {
          this.loadData();
          this.dataFormInit = Object.assign({}, this.dataForm);
          // if (isReload) {
          //   this.loadData();
          //   if (dataUpdate.length > 0) {
          //     // this.settingService.setBidsIsRefesh(this.tenantId);
          //     this.reloadStatusBatchVersions.emit();
          //   }
          // }
          this.toast.success('Edge has been updated');
        }
        else {
          (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.value.edgeId == dataUpdate[0].edgeId) {
              let old = _.find(this.dataFormInit.value.dataUpdate, function (o) { return o.edgeId == dataUpdate[0].edgeId; });
              e.patchValue({
                alias: old.alias,
                helperText: old.helperText,
                edgeColor: old.edgeColor,
                straight: old.straight,
                radius: old.radius,
                undermount: old.undermount,
                onsite: old.onsite,
                frequentlyUsed: old.frequentlyUsed,
                applyHeight: old.applyHeight,
              });
              e.markAsPristine();
              e.markAsUntouched();
            }
          });
          this.toast.error(response.message ?? 'Update edge failed');
        }
      });
    }
  }

  // checkDuplicate(e: FormControl) {
  //   (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
  //     if (e.parent.value.edgeId != m.value.edgeId && e.parent.value.edgeType.trim().toUpperCase() == m.value.edgeType.trim().toUpperCase()) {
  //       e.setErrors({ 'hasDup': true });
  //     }
  //   });
  // }

  drop(event) {
    if (event.previousIndex != event.currentIndex) {
      this.loading = true;
      let tenantEdgeConfigurationId = this.data[event.previousIndex]?.tenantEdgeConfigurationId ?? 0
      let sortNumber = this.data[event.currentIndex]?.sortNumber ?? event.currentIndex + 1
      // if (tenantEdgeConfigurationId == 0 || sortNumber == 0) return;
      this.settingService.sortTenantEdgeConfiguration(tenantEdgeConfigurationId, sortNumber).then(res => {
        if (res.status) this.loadData();
        else this.toast.error('Sort edge failed');
      });
    }
  }

  add(): void {
    if (!this.edgeTypeId) return;
    this.loading = true;
    this.tenantEdgeConfigService.add(this.tenantId, this.edgeTypeId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Edge Type added.');
      this.loadData();
      this.edgeTypeId = 0;
    })
  }

  remove(edgeTypeId: number): void {
    if (!edgeTypeId) return;
    this.edgeTypeId = edgeTypeId;
    this.tenantEdgeConfigService.checkRemove(this.tenantId, this.edgeTypeId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.modalConfirm2.open();
        // this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.modalConfirm2.close();
    this.loading = true;
    this.tenantEdgeConfigService.remove(this.tenantId, this.edgeTypeId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Edge Type removed.');
      this.loadData();
      this.edgeTypeId = 0;
      this.replaceId = 0;
    });
  }

  changeSortOption() {
    this.loadData();
  }
}
