import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class LicenseTypeService {
    constructor(private http: AuthHttp) { }

    checkUserCountValid = (tenantId: number, license: any) =>
        this.http.post(`LicenseTypes/CheckUserCount/${tenantId}`, JSON.stringify(license)).toPromise()

    addLicenseType = (moduleId: number, obj: any) =>
        this.http.post(`LicenseTypes/AddLicenseType/${moduleId}`, JSON.stringify(obj)).toPromise()

    getLicenseTypeByModule = (moduleId: number) =>
        this.http.get(`LicenseTypes/GetLicenseTypeByModule/${moduleId}`).toPromise()

    getLicenseTypeByRoleModule = (moduleId: number, roleId: number) =>
        this.http.get(`LicenseTypes/GetLicenseTypeByRoleModule/${moduleId}/${roleId}`).toPromise()

    assignOrUnassignLicenseTypeToRole = (licenseTypeId: number, roleId: number, isAssign: boolean) =>
        this.http.put(`LicenseTypes/AssignOrUnassignLicenseTypeToRole/${licenseTypeId}/${roleId}/${isAssign}`).toPromise()

    getLicenseTypeByModuleTenant = (moduleId: number, tenantId: number) =>
        this.http.get(`LicenseTypes/GetLicenseTypeByModuleTenant/${moduleId}/${tenantId}`).toPromise()

    updateLicenseType = (licenseTypeId: number, model: any) =>
        this.http.post(`LicenseTypes/UpdateLicenseType/${licenseTypeId}`, JSON.stringify(model)).toPromise()

    deleteLicenseType = (licenseTypeId: number) =>
        this.http.delete(`LicenseTypes/DeleteLicenseType/${licenseTypeId}`).toPromise()
}
