import { BsModalComponent } from 'ng2-bs3-modal';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CostSchemaAssignmentService } from '../../../../services/cost-schema-assignment.service';
// import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { ChannelService } from '@app-admin/services/channel.service';
import { TenantChannelsService } from './tenant-channels.service';
import { catchError, finalize, of, Subscription } from 'rxjs';
// import { SlabGroupService } from '@app-admin/services/slab-group.service';
// import { Config } from '@app-admin/config';
import { TileTenantChannelsService } from '../../tile/tenant-channels/tenant-channels/tenant-channels.service';
import { ReassignChannelModalService } from './reassign-channel-modal/reassign-channel-modal.service';
// import { CostSchemaService } from '@app-admin/services/cost-schema.service';

@Component({
  selector: 'tenant-channels',
  templateUrl: './tenant-channels.html',
  styleUrls: ['./tenant-channels.scss']
})

export class TenantChannelComponent implements OnInit, OnDestroy {
  @Input() tenantId: any;
  @Input() enabledEdit: boolean;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modal') modal: BsModalComponent;
  loading = false;
  data: any[];
  // costSchemas: any[];
  // priceSchemas: any[];
  // slabGroups: any[];
  dataForm: FormGroup;
  dstChannelId: number;
  ok: boolean;
  dataSelect: any;
  item: any;

  // Add Channel
  @ViewChild('modalAddChannel') modalAddChannel: BsModalComponent;
  addChannelForm: FormGroup;
  @ViewChild('modalConfirmDelete') modalConfirmDelete: BsModalComponent;
  @ViewChild('modalConfirmApplyChange') modalConfirmApplyChange: BsModalComponent;
  channelIdDelete: number;

  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private costSchemaAssignmentService: CostSchemaAssignmentService,
    // private settingService: SettingService,
    // private costSchemaService: CostSchemaService,
    // private slabGroupService: SlabGroupService,
    private channelService: ChannelService,
    public tenantChannelsService: TenantChannelsService,
    public tileTenantChannelsService: TileTenantChannelsService,
    private reassignChannelModalService: ReassignChannelModalService,
  ) {
    this.addChannelForm = fb.group({
      channelName: ['', Validators.required],
      // priceSchemaId: ['', Validators.required],
      // costSchemaId: ['', Validators.required],
      // slabGroupId: ['', Validators.required],
      active: [true, Validators.required],
      tenantId: [null, Validators.required],
    });

    this.subscription = this.tenantChannelsService.getMessages().subscribe(data => {
      if (!data) {
        return;
      }

      switch (data.messageType) {
        case TenantChannelsService.MessageTypes.RELOAD_PARENT_COMP:
          this.loadData();
          break;
        case TenantChannelsService.MessageTypes.DELETE_CHANNEL:
          const payload = data.payload;
          this.channelIdDelete = payload.channelId;
          this.submitDeleteChannel();
          break;
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy(): void {
  }

  confirm(cancel: boolean = false) {
    if (cancel) {
      this.item.get('active').setValue(true);
      this.modal.close();
      return;
    }
    if (!this.ok) {
      this.ok = true;
      return;
    }
    this.modal.close();
    if (this.ok && this.dstChannelId > 0) {
      this.item.get('dstChannelId').setValue(this.dstChannelId);
      // this.item.get('costSchemaId').setValue(0);
      this.data.find(i => i.id == this.item.value.id).active = false;
    }
    else {
      this.item.get('active').setValue(true);
    }
  }
  changeActive(item) {
    this.ok = false;
    this.dstChannelId = 0;
    this.item = item;
    if (!item.value.active) {
      this.dataSelect = this.data.filter(x => x.channelNameAlias != item.value.channelNameAlias && x.active == true);
      if (!this.dataSelect || this.dataSelect.length < 1) { this.toast.warning('You can\'t deactivate the last channel.'); this.item.get('active').setValue(true); return; }
      this.modal.open();
    }
    else {
      this.item.get('dstChannelId').setValue(0);
      // this.item.get('costSchemaId').setValue(1);
    }
  }
  loadData() {
    this.loading = true;
    /* this.costSchemaService.getCostSchemasActive(Config.BusinessUnitId.Slab, this.tenantId).then(response => {
    this.settingService.getCostSchemas(this.tenantId, Config.BusinessUnitId.Slab).then((response) => {
      if (response.status) {
        this.costSchemas = response.data;
        this.settingService.getPriceSchemas(this.tenantId, Config.BusinessUnitId.Slab).then(schemaRes => {
          if (schemaRes.status) {
            this.priceSchemas = schemaRes.data; */
            this.costSchemaAssignmentService.getByTenant(this.tenantId).then(res => {
              if (res.status) {
                this.data = res.data;
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
              }
              else { this.toast.error('Load data failed'); }
            });
          /* }
          else {
            this.toast.error('Load pricing schemas failed');
          }
        });
      }
      else { this.toast.error('Load cost schemas failed'); }
    });
    this.slabGroupService.getSlabGroupsByTenantId(this.tenantId).then(res => {
      if (res.status) { this.slabGroups = res.data; }
      else { this.toast.error('Load slab groups failed'); }
    }); */
  }

  filterData() {
    const dataUpdate = [];
    this.data.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    this.setEnableForm(this.enabledEdit);
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      channelNameAlias: e.channelNameAlias,
      dstChannelId: 0,
      active: e.active,
      /* schemaPriceId: e.schemaPriceId,
      schemaPriceName: e.schemaPriceName,
      costSchemaId: e.costSchemaId,
      costSchemaName: e.costSchemaName,

      tilePriceSchemaId: e.tilePriceSchemaId,
      tilePriceSchemaName: e.tilePriceSchemaName,
      tileCostSchemaId: e.tileCostSchemaId,
      tileCostSchemaName: e.tileCostSchemaName, */

      updatedBy: e.updatedBy,
      updatedOn: e.updatedOn,
      channelId: e.channelId,
      standard: e.standard,
      tenantId: e.tenantId,
      isSaleModule: e.isSaleModule,
      isProductionModule: e.isProductionModule,

      businessUnitId: e.businessUnitId,
    });
  }

  save(isOpenPopupConfirm: boolean) {
    if (isOpenPopupConfirm && this.tenantChannelsService.arrReassignChannel.length > 0) {
      this.modalConfirmApplyChange.open();
      return;
    }

    const dataUpdate = [];
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      dataUpdate.push(this.createItemUpdate(e.value));
    });

    if (!this.validate(dataUpdate)) {
      this.toast.error('The channel name should not empty!');
      return;
    }

    if (dataUpdate.length > 0 || this.tenantChannelsService.isDirty() || this.tileTenantChannelsService.isDirty()) {
      this.costSchemaAssignmentService.applyChanges(this.tenantId, {
        deleteChannelIds: this.tenantChannelsService.arrDeleteChannel,
        // deleteCostSchemaAssignmentIds: this.tenantChannelsService.arrDelete,
        updateModels: dataUpdate,
        updateIsDefaultModels: this.tenantChannelsService.arrUpdateIsDefault,
        // addModels: this.tenantChannelsService.arrAdd,
        reassignChannelModels: this.tenantChannelsService.arrReassignChannel,

        // Tile
        // tileDeleteCostSchemaAssignmentIds: this.tileTenantChannelsService.arrDelete,
        tileUpdateIsDefaultModels: this.tileTenantChannelsService.arrUpdateIsDefault,
        // tileAddModels: this.tileTenantChannelsService.arrAdd,
      }).then(res => {
        if (!res.status) {
          this.toast.error(res.message);
          return;
        }
        this.toast.success('Tenant has been updated!');
        this.loadData();
        this.tenantChannelsService.clear();
        this.tileTenantChannelsService.clear();
        this.modalConfirmApplyChange.close();
      });
    }

    // if (dataUpdate.length > 0) {
    //   this.costSchemaAssignmentService.updateByTenant(dataUpdate).then(res => {
    //     if (res.status) {
    //       this.toast.success('Tenant has been updated!');
    //       this.loadData();
    //       this.settingService.setBidsIsRefreshByTenant(this.tenantId);
    //     } else {
    //       this.toast.error(res.message);
    //       this.loadData();
    //     }
    //   });
    // }
  }

  private validate(dataUpdate: any[]) {
    let vaild = true;
    dataUpdate.forEach(e => {
      if (!e.channelNameAlias.trim()) { vaild = false; }
    });
    return vaild;
  }

  createItemUpdate(e) {
    return {
      id: e.id,
      channelNameAlias: e.channelNameAlias.trim(),
      dstChannelId: e.dstChannelId,
      active: /* !!e.schemaPriceId && !!Number(e.costSchemaId) ? */ e.active /* : false */,
      // schemaId: Number(e.costSchemaId),
      // priceSchemaId: Number(e.schemaPriceId),
      tenantId: this.tenantId,
      channelId: e.channelId,
      standard: e.standard,
      isSaleModule: e.isSaleModule,
      isProductionModule: e.isProductionModule,
      // tileCostSchemaId: e.tileCostSchemaId,
      // tilePriceSchemaId: e.tilePriceSchemaId,

      businessUnitId: e.businessUnitId,
    };
  }

  setEnableForm(enabledEdit: boolean) {
    this.enabledEdit = enabledEdit;
    if (this.enabledEdit) {
      this.dataForm.enable();
    } else {
      this.dataForm.disable();
    }
  }

  addChannel() {
    if (this.enabledEdit && (this.dataForm.dirty || this.tenantChannelsService.isDirty() || this.tileTenantChannelsService.isDirty())) {
      this.toast.error('Please apply changes first.');
      return;
    }

    // Reset form
    this.addChannelForm.reset();

    // Set default value
    this.addChannelForm.get('tenantId').setValue(this.tenantId);
    // this.addChannelForm.get('slabGroupId').setValue(-1);
    this.addChannelForm.get('active').setValue(true);

    // Open modal
    this.modalAddChannel.open();
  }

  saveChannel() {
    if (this.addChannelForm.valid) {
      this.addChannelForm.disable();

      this.channelService.addChannel(this.addChannelForm.value).then(response => {
        this.addChannelForm.enable();
        if (response.status) {
          this.toast.success('The channel has been added');
          this.loadData();
          this.modalAddChannel.close();
        } else {
          this.toast.error('Add channel failed');
        }
      });
    }
  }

  private deleteChannel(channelId: number): void {
    this.channelIdDelete = channelId;
    this.modalConfirmDelete.open();
  }

  submitDeleteChannel() {
    this.tenantChannelsService.arrDeleteChannel.push(this.channelIdDelete);

    let index = this.data.findIndex(x => x.channelId == this.channelIdDelete);
    this.data.splice(index, 1);
    this.filterData();

    this.channelIdDelete = 0;
    this.modalConfirmDelete.close();

    // this.channelService.deleteChannel(this.channelIdDelete).then(response => {
    //   if (response.status) {
    //     this.toast.success('The channel has been deleted');
    //     this.loadData();
    //     this.modalConfirmDelete.close();
    //   } else {
    //     this.toast.error(response.message);
    //   }
    // });
  }

  passingDataToModal(currentRow: { id: number, channelId: number, channelNameAlias: string }, isDeleteAfterReassign: boolean) {
    this.tenantChannelsService.sendMessage(
      TenantChannelsService.MessageTypes.SEND_TO_MODAL_REASSIGN_CHANNEL,
      {
        currentRow: {
          // costSchemaAssignmentId: currentRow.id,
          tenantChannelId: currentRow.id,
          currentChannelName: currentRow.channelNameAlias,
          currentChannelId: currentRow.channelId,
        },
        channels: this.data
          .filter(x => x.channelId != currentRow.channelId
            /* && x.isSaleModule == currentRow.isSaleModule
            && x.isProductionModule == currentRow.isProductionModule */)
          .map(x => ({ channelId: x.channelId, channelName: x.channelNameAlias })),
        isDeleteAfterReassign: isDeleteAfterReassign,
      }
    );
  }

  clickDelete(currentRow: { id: number, channelId: number, channelNameAlias: string }): void {
    this.channelService.validateDeleteChannel(currentRow.channelId)
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => {}))
      .subscribe(response => {
        if (!response.status) {
          this.passingDataToModal(currentRow, true);
          this.openModal();
          return;
        }
        this.deleteChannel(currentRow.channelId);
      });
  }

  private openModal(): void {
    this.reassignChannelModalService.open('reassignChannelModal');
  }
}
