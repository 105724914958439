/// <reference types="@types/google.maps" />
import { SettingService } from './../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TenantsService } from '@app-admin/services/tenants.service';
import { Router } from '@angular/router';
import { LocationService } from '@app-admin/services/location.service';
declare var $:any;
@Component({
  selector: 'app-new-tenant',
  templateUrl: './new-tenant.html',
  styleUrls: ['./new-tenant.scss']
})
export class NewTenantComponent implements OnInit, AfterViewInit {

  @ViewChild("pac") placeAutocompleteInput: ElementRef;

  form: FormGroup;
  // formCostSchema: FormArray;
  states: any;
  // schemas: any;
  timezones: any;
  // lstchannel: any;
  error_message: string;
  googleAddressSuggestOptions;

  isLoading: boolean = false;

  constructor(
    private tenantService: TenantsService,
    private router: Router,
    private settingService: SettingService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      // tenantId: [0],
      tenantName: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
      locationName: ['', [Validators.required]],
      company: ['', [Validators.required]],
      // website: ['', [Validators.required, Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
      // phone: ['', [Validators.pattern('[0-9]{10}')]],
      timeZoneId: ['', Validators.required],
      // active: [true],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      // channels: this.formBuilder.array([ ])
      placeId: [''],
      addressSuggestOptionType: [1, Validators.required],
      addressSuggestRadiusRange: [200, Validators.compose([Validators.required,Validators.min(1),Validators.pattern('(^[0-9]*$)')])],
    });
  }


  ngOnInit() {
    $('body').layout('fix');
    this.getAddressSuggestOptions();
    this.getListState();
    this.getListTimeZone();
    // this.getAllSchemas();
    // this.getAllChanel();
  }

  ngAfterViewInit(): void {
    this.initPac();
  }

  getAddressSuggestOptions(){
    this.locationService.getGoogleAddressSuggestOptions().then(res=>{
      this.googleAddressSuggestOptions = res.data;
    });
  }

  save(): void {
    this.isLoading = true;
    this.tenantService.CreateTenant(this.form.getRawValue()).then(res => {
      this.isLoading = false;
      if(res.status){
        this.toastr.success('Create tenant succeess!');
        this.router.navigate(['/tenants']);
      }
      else this.toastr.error(res.message);
    });
  }
  cancel(){
    this.router.navigate(['/tenants']);
  }
  // getAllChanel(){
  //   this.settingService.getChannel().then(res=> {
  //     if(res.status) {
  //       this.lstchannel = res.data;

  //       this.lstchannel.forEach(e => {
  //          this.channels.push(this.createItemView(e));
  //       });
  //     }
  //     else this.toastr.error('Can\'t load list channels');
  //   });
  // }
  private getListState(){
    this.settingService.getListState().then(res => {
      if(res.status){
        this.states = res.data;
      }
      else this.toastr.error('Can\'t load list state');
    });
  }
  private getListTimeZone() {
    this.tenantService.getTimeZone().then(response => {
      if (response.status) this.timezones = response.data
      else this.toastr.error('Can\'t load list timezone');
    });
  }
  // getAllSchemas(){
  //   this.settingService.getAllSchemas().then(res => {
  //     if(res.status) this.schemas = res.data;
  //     else this.toastr.error('Can\'t load list cost schema');
  //   });
  // }

  // createItemView(e): FormGroup{
  //   return this.formBuilder.group({
  //     costSchemaId: 0,
  //     channelId: [e.channelId, [Validators.required]],
  //     channelName: e.channelName,
  //     active: !!e.active
  //   });
  // }

  // get channels(): FormArray{
  //   return this.form.get('channels') as FormArray;
  // }
  get address1(): FormControl{
    return this.form.get('address1') as FormControl;
  }
  get address2(): FormControl{
    return this.form.get('address2') as FormControl;
  }
  get city(): FormControl{
    return this.form.get('city') as FormControl;
  }
  get state(): FormControl{
    return this.form.get('state') as FormControl;
  }
  get postalCode(): FormControl{
    return this.form.get('postalCode') as FormControl;
  }
  get placeId(): FormControl {
    return this.form.get('placeId') as FormControl;
  }
  // get tenantId(): FormControl {
  //   return this.form.get('tenantId') as FormControl;
  // }
  get tenantName(): FormControl {
    return this.form.get('tenantName') as FormControl;
  }
  get locationName(): FormControl {
    return this.form.get('locationName') as FormControl;
  }
  get company(): FormControl {
    return this.form.get('company') as FormControl;
  }
  // get website(): FormControl {
  //   return this.form.get('website') as FormControl;
  // }
  // get phone(): FormControl {
  //   return this.form.get('phone') as FormControl;
  // }
  get timeZoneId(): FormControl {
    return this.form.get('timeZoneId') as FormControl;
  }
  get addressSuggestOptionType(): FormControl {
    return this.form.get('addressSuggestOptionType') as FormControl;
  }
  get addressSuggestRadiusRange(): FormControl {
    return this.form.get('addressSuggestRadiusRange') as FormControl;
  }

  // START: Map
  private initPac(): void {
    let autocomplete = new google.maps.places.Autocomplete(this.placeAutocompleteInput.nativeElement, {
      componentRestrictions: { country: ['us'] },
    });

    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace();

      let addressObj = this.getAddressObj(place);
      this.patchAddressForm(addressObj);
    });
  }

  private getAddressObj(place: google.maps.places.PlaceResult) {
    let streetNumber = place.address_components.find(x => x.types.find(t => ['street_number'].includes(t)));
    let route = place.address_components.find(x => x.types.find(t => ['route'].includes(t)));
    let city = place.address_components.find(x => x.types.find(t => ['locality'].includes(t)));
    if (!city) {
      city = place.address_components.find(x => x.types.find(t => ['sublocality_level_1'].includes(t)));
    }
    let state = place.address_components.find(x => x.types.find(t => ['administrative_area_level_1'].includes(t)));
    let postalCode = place.address_components.find(x => x.types.find(t => ['postal_code'].includes(t)));

    return {
      address: `${streetNumber?.short_name || ''} ${route?.short_name || ''}`,
      city: city?.short_name || '',
      stateAbbreviation: state?.short_name || '',
      postalCode: postalCode?.short_name || '',

      placeId: place.place_id,
      origin: '',
      destination: place.formatted_address,
      orgLat: 0,
      orgLng: 0,
      dstLat: place.geometry.location.lat(),
      dstLng: place.geometry.location.lng(),
      duration: 0,
      durationInHour: 0,
      distance: 0,
      distanceInMile: 0,
    };
  }

  private patchAddressForm(addressObj): void {
    this.address1.setValue(addressObj.address);
    this.city.setValue(addressObj.city);
    this.state.setValue(addressObj.stateAbbreviation);
    this.postalCode.setValue(addressObj.postalCode);

    this.placeAutocompleteInput.nativeElement.blur();
    this.onPacOutFocus(false);
    this.placeId.setValue(addressObj.placeId);

    this.cdr.detectChanges();
  }

  onPacOutFocus(isCustomAddress): void {
    if(isCustomAddress){
      this.city.enable();
      this.state.enable();
      this.postalCode.enable();
      this.placeId.setValue(null);
      this.city.setValue(null);
      this.state.setValue(null);
      this.postalCode.setValue(null);
    }else{
      this.city.disable();
      this.state.disable();
      this.postalCode.disable();
    }
  }
  // END: Map
}
