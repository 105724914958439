import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ObjectRoles, UserService } from '../../../services/user.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { UserDetailService } from '../user-detail.service';
import { UserAssignedTenantsComponent } from './assigned-tenants/assigned-tenants';
declare var $: any;

@Component({
  templateUrl: './details.html'
})

export class UserDetailsComponent implements OnInit {
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirmTurnOfGlobalRoles') modalConfirmTurnOfGlobalRoles: BsModalComponent;
  @ViewChild('modalConfirmDelete') modalConfirmDelete: BsModalComponent;
  @ViewChild('userAssignedTenants') userAssignedTenants: UserAssignedTenantsComponent;
  @ViewChild('modalConfirmRemoveStaff') modalConfirmRemoveStaff: BsModalComponent;
  userId: any;
  userInfo: any;
  tenantRoles = [];
  globalRoles: any;
  listDataActivity = [];
  assignedTenants = [];
  lstAssignedTenants = [];
  editUserForm: FormGroup;
  loading = false;
  loadLog = false;
  showSave = true;
  showResetPass = true;
  showSendInvitation = true;
  toggleControlUserActive = false;
  toggleControlAccessGlobalRoles = false;
  lockEmail = true;
  EmailDefaultValue: string;
  paginationCount = 0;
  paging = {
    itemPerPage: 20,
    pageNumber: 1
  };

  subscription: Subscription;

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private service: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userDetailService: UserDetailService) {

    this.editUserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-z]{2,6}?([.][a-z]{2})?')])],
      phone: ['', Validators.pattern('[0-9]{10}')],
      arrTenantRoleUser: [[]],
      employee: [false],
      staff: [false],
      active: [false],
      flagQXMAdminAccess: [false]
    });

    activatedRoute.params.subscribe(params => {
      this.userId = params.id;
      if (!Number(this.userId)) { this.router.navigate(['/not-found']); }
    });

    this.subscription = this.userDetailService.getMessages().subscribe(data => {
      if (!data) {
        return;
      }

      switch (data.messageType) {
        case UserDetailService.MessageTypes.RELOAD_PARENT_COMP:
          this.getUserInfo();
          break;
      }
    });
  }

  get firstName(): FormControl {
    return this.editUserForm.get('firstName') as FormControl;
  }
  getErrorFirstName() {
    return this.editUserForm.hasError('required') ? 'First name is required' : '';
  }

  get lastName(): FormControl {
    return this.editUserForm.get('lastName') as FormControl;
  }
  getErrorLastName() {
    return this.lastName.hasError('required') ? 'Last name is required' : '';
  }

  get email(): FormControl {
    return this.editUserForm.get('email') as FormControl;
  }

  getErrorEmail() {
    if (this.email.hasError('required')) {
      return 'Email is required';
    } else if (this.email.hasError('email') || this.email.hasError('pattern')) {
      return 'Invalid email format';
    }
  }

  get phone(): FormControl {
    return this.editUserForm.get('phone') as FormControl;
  }
  getErrorPhone() {
    return this.phone.hasError('pattern') ? 'Invalid phone number format' : '';
  }

  ngOnInit() {
    $('body').layout('fix');
    this.reload();
  }

  reload() {
    this.getUserInfo();
    this.getUserActivity();
  }

  getUserInfo() {
    this.service.getUserInfo(this.userId).then(res => {
      if (res.status) {
        this.userInfo = res.data;
        this.editUserForm.patchValue({
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          employee: this.userInfo.employee,
          staff: this.userInfo.staff ?? false,
          active: this.userInfo.active ?? false,
          flagQXMAdminAccess: this.userInfo.flagQXMAdminAccess ?? false
        });
        this.globalRoles = JSON.parse(JSON.stringify(this.userInfo.listRole))[0];
        this.tenantRoles = this.userInfo.listRole;
        this.tenantRoles.shift();
        this.toggleControlAccessGlobalRoles = this.userInfo.flagQXMAdminAccess ?? false;
        this.toggleControlUserActive = this.userInfo.active ?? false;
        this.assignedTenants = this.userInfo.assignedTenants;
        this.lstAssignedTenants = this.assignedTenants.filter(x => x.isAssigned === true);
      }
      else {
        this.toastr.error(res.data.error);
      }
    });
  }

  getUserActivity() {
    this.loadLog = true;
    this.service.getListActivities(this.userId, this.paging).then(res => {
      this.loadLog = false;
      if (res.status) {
        this.listDataActivity = res.data.data;
        this.paginationCount = res.data.totalPage;
      }
      else { this.toastr.error(res.message); }
    });
  }

  resetPassword() {
    this.service.resetPassword({ email: this.email.value }).then(res => {
      if (res.status) {
        this.modalConfirm.close();
        this.toastr.success(res.message);
        this.getUserActivity();
      }
      else { this.toastr.error(res.message); }
    });
  }

  save() {
    this.loading = true;
    const assignTenantIds = _.map(_.filter([...this.assignedTenants], 'isAssigned'), 'tenantId');
    const listRole = [];
    if (this.toggleControlAccessGlobalRoles) {
      const arrRole = [];
      this.globalRoles.arrRole.forEach(x => { if (x.isCheck == '1') { arrRole.push(x.roleId); } });
      const obj = { tenantId: this.globalRoles.tenantId, arrRole };
      listRole.push(obj);
    }
    this.tenantRoles.forEach(e => {
      if (_.indexOf(assignTenantIds, e.tenantId) !== -1) {
        const arrRole = [];
        e.arrRole.forEach(r => { if (r.isCheck == '1') { arrRole.push(r.roleId); } });
        const obj = { tenantId: e.tenantId, arrRole };
        listRole.push(obj);
      }
    });

    this.editUserForm.patchValue({ arrTenantRoleUser: listRole });
    if (this.editUserForm.valid) {
      this.service.updateUserInfo(this.userId, { ...this.editUserForm.value, assignTenantIds }).then(response => {
        this.loading = false;
        if (response.status) {
          this.toastr.success('User has been updated.', 'Success');
          this.reload();
          if(!this.editUserForm.value?.staff && this.userInfo.staff) {
            this.userAssignedTenants.submitNone();
          }
        }
        else { this.toastr.error(response.message, 'Error'); }
      }).catch(() => {
        this.loading = false;
        this.toastr.error('Internal Server Error', 'Error');
      });
    }
  }

  goToPage(n: number): void {
    if (this.paging.pageNumber === n) {
      return;
    }
    this.paging.pageNumber = n;
    this.getUserActivity();
  }

  onNext(): void {
    this.paging.pageNumber = +this.paging.pageNumber;
    this.paging.pageNumber++;
    this.getUserActivity();
  }

  onPrev(): void {
    this.paging.pageNumber = +this.paging.pageNumber;
    this.paging.pageNumber--;
    this.getUserActivity();
  }

  toggleControlGlobalRoles() {
    this.modalConfirmTurnOfGlobalRoles.close();
    this.toggleControlAccessGlobalRoles = !this.toggleControlAccessGlobalRoles;
    this.editUserForm.get('flagQXMAdminAccess').setValue(this.toggleControlAccessGlobalRoles);
  }

  confirmAccessGlobalRoles() {
    if (!!this.toggleControlAccessGlobalRoles) this.modalConfirmTurnOfGlobalRoles.open();
    else {
      this.toggleControlAccessGlobalRoles = !this.toggleControlAccessGlobalRoles;
      this.editUserForm.get('flagQXMAdminAccess').setValue(this.toggleControlAccessGlobalRoles);
    }
  }

  countRoleAssigned(tenant, moduleName: string = null) {
    const count = tenant?.arrRole?.filter(x => x.isCheck && (moduleName == null || x.moduleName == moduleName))?.length ?? 0;
    return (count > 0) ? '(' + count + ')' : '';
  }

  toggleUserActive() {
    this.toggleControlUserActive = !this.toggleControlUserActive;
    this.editUserForm.get('active').setValue(this.toggleControlUserActive);
  }

  resendInvitation() {
    this.loading = true;
    this.service.resendInvitation(this.userId).then(res => {
      if (res.status) {
        this.loading = false;
        this.toastr.success('Invitation has been resend');
      }
      else this.toastr.error('Resend invitation  failed');
    });
  }

  startEdit(event: any){
    event.preventDefault();
    this.lockEmail = !this.lockEmail;
  }

  cancelEdit(event: any) {
    event.preventDefault();
    this.lockEmail = !this.lockEmail;
    const emailElem = this.editUserForm.get('email') as FormControl;
    if(emailElem && this.userInfo){
      emailElem.setValue(this.userInfo.email);
    }
  }

  saveEmailImmediately(event: any) {
    event.preventDefault();
    if (this.editUserForm.valid && this.userInfo.email != this.email.value) {
      const data ={
        userId: this.userId,
        email:this.email.value,
      };
      this.service.updateEmailUser(data).then(response => {
        if (response.status) {
          this.service.resetPassword({ email: this.email.value }).then(res => {
            if (!res.status) {
              this.toastr.error(res.message);
            }
          });
        }
      }).catch(() => {
        this.loading = false;
        this.toastr.error('Internal Server Error', 'Error');
      });
    }
    this.lockEmail = !this.lockEmail;
  }

  deleteUser() {
    this.service.deleteUser(this.userId).then(response => {
        if (response.status) {
          this.loading = false;
          this.modalConfirmDelete.close();
          this.toastr.success('User has been deleted');
          this.service.addChangeLog({
            objId: this.userId,
            action: 'Remove Delegate Users of Account',
            actionDescription: `${this.firstName.value} ${this.lastName.value} was removed as authorized user`,
            objType: 56,
            actionType: 4
          });
          this.router.navigate(['/users']);
        }
        else { this.toastr.error('Delete user failed'); }
    });
  }

  getNormalizedModuleName(moduleName: string){
    return moduleName.trim().replace(' ','').toLowerCase();
  }

  getTenantsInModule(moduleName: string){
    return this.tenantRoles.filter(role => {
      return role.modules.includes(moduleName);
    });
  }

  getTenantRoles(tenant, moduleName: string){
    return tenant.arrRole.filter(i=>i.moduleName == moduleName);
  }

  confirmRemoveStaff(event) {
    if(event) {
      this.save()
      this.modalConfirmRemoveStaff.close();
    } else {
      this.modalConfirmRemoveStaff.close();
    }

  }
  confirmSave() {
    if(!this.editUserForm.value?.staff && this.userInfo.staff) {
      this.modalConfirmRemoveStaff.open();
    } else {
      this.save()
    }
  }
}
