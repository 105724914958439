import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class InventoryService {

  controller = 'Inventory';

  constructor(private http: AuthHttp) { }

  getConfigTemplate(tenantId, itemClassId): Promise<any> {
    return this.http.get(`${this.controller}/GetConfigTemplate/${tenantId}/${itemClassId}`).toPromise();
  }

  updateConfigTemplate(tenantId, itemClassId, configTemplates): Promise<any> {
    return this.http.post(`${this.controller}/UpdateConfigTemplate/${tenantId}/${itemClassId}`, JSON.stringify(configTemplates)).toPromise();
  }
}
