import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ToastrService } from 'ngx-toastr';
import { LicenseTypeService } from '@app-admin/services/license-type.service';
import { PermissionService } from '@app-admin/services/permission.service';
import { SettingService } from '@app-admin/services/setting.service';
import { CommentDocumentLevelAccess, ObjectPermission, UserService } from '@app-admin/services/user.service';
declare var $: any;

@Component({
  templateUrl: './role-detail.html',
  styleUrls: ['./role-detail.scss']
})

export class RoleDetailComponent implements OnInit {

  @ViewChild('modalDelete') modalDelete: BsModalComponent;

  roleId: any;
  role: any;
  curTab: any = {};
  tabs = [];
  rolesIdDelete: any;
  roleUnassign = 0;
  userUnassign = 0;
  licenseTypes: any = [];
  checkAllCommentDocumentLevelAccess = false;
  checkAllChannel = false;
  checkAllDocTypeView = false;
  checkAllDocTypeAdd = false;
  checkAllDocTypeEdit = false;
  checkAllDocTypeDelete = false;
  checkAllCommentTypeView = false;
  checkAllCommentTypeAdd = false;
  checkAllCommentTypeEdit = false;
  checkAllCommentTypeDelete = false;
  listCommentDocumentLevelAccess: any;
  channelList = [];
  docTypeList = [];
  cmtTypeList = [];
  pageCount = 0;
  filter = {
    tenantId: 0,
    pageSize: 20,
    pageNo: 1,
    roleId: 0,
  };
  users = [];
  loadingUser = false;
  editRoleForm: FormGroup;

  originPermissions = [];

  enableBtnSave = false;

  isWorkerRole = false;

  isSchedulingModule = false;
  isDirector = false;
  isAdmin = false;
  isInventory = false;
  hasChangeRoleDocumentTypes: boolean = false;
  hasChangeRoleCommentTypes: boolean = false;

  constructor(
    activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private licenseTypeService: LicenseTypeService,
    private permissionService: PermissionService,
    private userService: UserService,
    private settingService: SettingService
  ) {
    activatedRoute.params.subscribe(params => {
      this.roleId = params.id;
      if (!Number(this.roleId)) { this.router.navigate(['/not-found']); }
    });

    this.tabs = [
      { id: 'app_permissions', name: 'Application Permissions' },
      // { id: 'admin_permissions', name: 'Administrator Permissions' },
      { id: 'users', name: 'Assigned Users' },
      { id: 'accesses', name: 'Data Access' },
      { id: 'sys_permissions', name: 'License Types' }
    ];
    this.curTab = this.tabs[0];
  };

  ngOnInit() {
    this.load();
  }

  load() {
    this.permissionService.getRoleDetailWebAdmin(this.roleId).then((res) => {
      if (res.status) {
        this.role = res.data;
        this.isSchedulingModule = this.role.moduleId == 3;
        this.isDirector = this.role.moduleId == 4;
        this.isAdmin = this.role.moduleId == 5;
        this.isInventory = this.role.moduleId == 8;
        this.isWorkerRole = this.role.moduleId == 6 || this.role.moduleId == 7;
        if (this.isWorkerRole) this.tabs.find(x => x.id === 'users').name = 'Assigned Workers';

        this.filter.tenantId = Number(this.role.tenantId);
        this.filter.roleId = this.role.roleId;

        this.getDataCommentDocumentLevelAccess();
        this.getAllUsers();

        let permissions = this.role.permissions || [];
        permissions.forEach(e => {
          switch (e.permissionId) {
            case ObjectPermission.OthersAccount:
            case ObjectPermission.OthersProject:
            case ObjectPermission.Location_ManageLocations:
            case ObjectPermission.Location_ManageRegions:
              e = Object.assign(e, { hideAdd: true });
              if (this.role.moduleId == 3) {
                e = Object.assign(e, { hideDelete: true });
              }
              break;
            case ObjectPermission.OthersBid:
            case ObjectPermission.OthersQuote:
            case ObjectPermission.OtherOpportunities:
              e = Object.assign(e, { hideAdd: true }); break;
            case ObjectPermission.Project:
              if (this.role.moduleId == 3) {
                e = Object.assign(e, { hideDelete: true });
              }
              break;
            case ObjectPermission.AccountPricingSchema:
            case ObjectPermission.AccountTransferOwnership:
            case ObjectPermission.Resources:
            case ObjectPermission.Settings:
            case ObjectPermission.CommitWorkOrder:
              e = Object.assign(e, { hideAdd: true, hideDelete: true }); break;
            case ObjectPermission.BidMaterial:
              e.allowView = true;
              e = Object.assign(e, { hideView: true, hideDelete: true, hideEdit: true }); break;
            case ObjectPermission.BidPriceSchemaAssignment:
            case ObjectPermission.CommunityMaterialGroupChange:
            case ObjectPermission.BidSlabGroup:
            case ObjectPermission.BidItemGroup:
            case ObjectPermission.ContractOwnership:
            case ObjectPermission.BidPriceOverride:
            case ObjectPermission.ChangeProjectChannel:
            case ObjectPermission.ModifyDirectAccountCustomerAssignment:
            case ObjectPermission.ExcludeApplicationFromBidLevelCharges:
            case ObjectPermission.MaterialGroupPricing:
            case ObjectPermission.ItemGroupPricing:
            case ObjectPermission.EdgeGroupPricing:
            case ObjectPermission.EditMaterialGroupCost:
            case ObjectPermission.EditItemGroupCost:
            case ObjectPermission.EditEdgeGroupCost:
            case ObjectPermission.EditTileGroupCost:
            case ObjectPermission.Estimator:
            case ObjectPermission.SubmittedOrders:
            case ObjectPermission.NonStandardOptionQuotes:
            case ObjectPermission.ModifyProjectStatus:
            case ObjectPermission.OptimizeSlabCount:
            case ObjectPermission.SlabSalesOrderServiceLocation:
            case ObjectPermission.ModifyClosedProjectStatus:
            case ObjectPermission.ForceCompleteWorkOrders:
            case ObjectPermission.EnableProjectScheduling:
            case ObjectPermission.ExcludeProjectFromScheduling:
            case ObjectPermission.NonExecutionAppointmentCompletion:
            case ObjectPermission.ManualOverrideCompletedAppointments:
            case ObjectPermission.ImportOrderDates:
            case ObjectPermission.ExportOrders:
            case ObjectPermission.ModifyBidOwnership:
            case ObjectPermission.ModifyProjectOwnership:
            case ObjectPermission.ChangeStatusOfCompletedSalesOrders:
            case ObjectPermission.TenantProjectMove:
            case ObjectPermission.MoveProjectToAccount:
            case ObjectPermission.SaleOrderMaterialAdjustments:
            case ObjectPermission.SaleOrderProjectInformation:
            case ObjectPermission.MergeAccount:
            case ObjectPermission.PreciseSlabAdjustments:
            case ObjectPermission.CompleteWorkOrders:
            case ObjectPermission.ChangeAppointmentStatus:
            case ObjectPermission.AssignLicenses:
            case ObjectPermission.ChangeCommunityStatus:
            case ObjectPermission.PlanLock:
            case ObjectPermission.ApplicationWorkOrderCompleteStatus:
            case ObjectPermission.ReOpenCompletedWorkOrder:
            case ObjectPermission.ApproveTime:
            case ObjectPermission.ApplicationMaterialCostMethod:
              e.allowView = true;
              e = Object.assign(e, { hideAdd: true, hideView: true, hideDelete: true }); break;
            case ObjectPermission.DimensionAdjustment:
            case ObjectPermission.Dashboard:
            case ObjectPermission.Report:
            case ObjectPermission.ApplicationLineBudgetDetails:
            case ObjectPermission.ViewOtherEstimators:
            case ObjectPermission.Budget:
            case ObjectPermission.ExportLayout:
            case ObjectPermission.ExportEditor:
            case ObjectPermission.Director_AddSchema:
            case ObjectPermission.User_ResetPasswords:
            case ObjectPermission.User_TransferUser:
            case ObjectPermission.Worker_ResetPasswords:
            case ObjectPermission.Device_ManageDevices:
            case ObjectPermission.Role_AddRoles:
            case ObjectPermission.Role_ManageRoles:
            case ObjectPermission.Location_AddLocation:
            case ObjectPermission.Tax_AddTaxRegion:
            case ObjectPermission.Supplier_AddSupplier:
            case ObjectPermission.Setting_ModifyDefaults:
            case ObjectPermission.Setting_ManageCompany:
            case ObjectPermission.Setting_ManageServiceClass:
            case ObjectPermission.Setting_ManageReasonCode:
            case ObjectPermission.Setting_ManageEdge:
            case ObjectPermission.Catalog:
            case ObjectPermission.Stock:
            case ObjectPermission.Inventory_Settings:
            case ObjectPermission.MeasureThru:
              e = Object.assign(e, { hideAdd: true, hideEdit: true, hideDelete: true }); break;
            case ObjectPermission.Account:
            case ObjectPermission.Contract:
            case ObjectPermission.OthersContract:
              if (this.role.moduleId == 3) {
                e = Object.assign(e, { hideAdd: true, hideEdit: true, hideDelete: true });
              }
              break;
            case ObjectPermission.AdminQuoteDeletion:
            case ObjectPermission.BulkContactDeletion:
            case ObjectPermission.RemoveOrderScheduleDates:
            case ObjectPermission.ForceJobDeletion:
            case ObjectPermission.DeleteSubmittedEditOrders:
              e.allowView = true;
              e = Object.assign(e, { hideAdd: true, hideView: true, hideEdit: true }); break;
            case ObjectPermission.WorkOrders:
              e.allowView = true;
              e = Object.assign(e, { hideAdd: true, hideView: true }); break;
            case ObjectPermission.CommunityPricing:
            case ObjectPermission.AdvancedApplicationPricing:
            case ObjectPermission.NestingService:
            case ObjectPermission.LayoutEditor:
            case ObjectPermission.Director_DefaultRules:
            case ObjectPermission.Role_Permissions:
            case ObjectPermission.Role_DataAccess:
              e = Object.assign(e, { hideAdd: true, hideDelete: true }); break;
            case ObjectPermission.Appointments:
              if (this.role.moduleId == 3) e = Object.assign(e, { hideView: true, allowView: true });
              else if (this.role.moduleId == 7) e = Object.assign(e, { hideDelete: true, hideAdd: true, allowView: true });
              break;
            case ObjectPermission.Planning:
            case ObjectPermission.Execution:
              e = Object.assign(e, { hideAdd: true, hideDelete: true }); break;
            case ObjectPermission.Scheduling:
            case ObjectPermission.ManageWorkerPin:
              e = Object.assign(e, { hideDelete: true }); break;
            case ObjectPermission.AccountAuthorizations:
              e.allowView = true;
              e = Object.assign(e, { hideView: true, hideEdit: true }); break;
            case ObjectPermission.OrderScheduling:
              e = Object.assign(e, { hideEdit: true, hideDelete: true }); break;

            // case ObjectPermission.ReOpenCompletedWorkOrder:
            //   e.allowView = true;
            //   e = Object.assign(e, { hideView: true, hideDelete: true, hideAdd: true }); break;

            default:
              break;
          }

          // For permission estimator
          // switch (e.permissionId) {
          //   case ObjectPermission.Bid:
          //   case ObjectPermission.Quote:
          //   case ObjectPermission.Contract:
          //     if (!this.role.estimator) { e = Object.assign(e, { disabledView: true }); }
          //     break;
          // }
        });
        this.role.permissions = permissions;
        this.originPermissions = JSON.parse(JSON.stringify(permissions))
        this.role.categoryId = 1;

        this.changeCategory(this.role);
      } else this.toastr.error('Load role failed');
    });
  }

  getLicenseType() {
    this.licenseTypes = [];
    this.licenseTypeService.getLicenseTypeByRoleModule(this.role.moduleId, this.roleId)
      .then((res) => {
        if (!res.status) {
          this.toastr.error('Can\'t get list License Types');
          return;
        }
        this.licenseTypes = res.data;

        this.listCommentDocumentLevelAccess.forEach((x) => {
          x.checked = this.role.roleCommentDocumentLevelAccess.some(xx => xx.commentDocumentLevelAccessId == x.id);
          if (x.id === CommentDocumentLevelAccess.WorkOrder) x.alwaysTrue = true;
        });
        this.channelList.forEach(e => {
          e.checked = this.role.channelIds.find((i: number) => i === Number(e.id));
        });
        this.cmtTypeList.forEach(e => {
          const x = this.role.roleCommentTypes.find(c => c.commentTypeId == e.commentTypeId);
          e.allowView = x?.allowView ?? false;
          e.allowAdd = x?.allowAdd ?? false;
          e.allowEdit = x?.allowEdit ?? false;
          e.allowDelete = x?.allowDelete ?? false;
        });
        this.docTypeList.forEach(e => {
          const x = this.role.roleDocumentTypes.find(d => d.documentTypeId == e.documentTypeId);
          e.allowView = x?.allowView ?? false;
          e.allowAdd = x?.allowAdd ?? false;
          e.allowEdit = x?.allowEdit ?? false;
          e.allowDelete = x?.allowDelete ?? false;
        });
        this.check(false);
      });
  }

  private getDataCommentDocumentLevelAccess() {
    this.settingService.getCommentDocumentLevelAccess().then(res => {
      if (res.status) {
        this.listCommentDocumentLevelAccess = res.data;
        this.getChannels();
      }
      else {
        this.toastr.error('Load channels failed');
      }
    });
  }

  private getChannels() {
    this.settingService.getDefaultChannels(this.role.tenantId, this.role.moduleId, 0).then(res => {
      if (res.status) {
        // this.channels = res.data;
        this.channelList = res.data.map(c => ({ id: c.channelId, itemName: c.channelName }));
        this.getDocumentType();
      }
      else {
        this.toastr.error('Load channels failed');
      }
    });
  }

  private getDocumentType() {
    this.permissionService.getAllDocumentType().then(res => {
      if (res.status) {
        this.docTypeList = res.data;
        this.getCommentTypes();
      }
      else { this.toastr.error('Load document types failed'); }
    });
  }

  private getCommentTypes() {
    this.permissionService.getListCommentTypes().then(res => {
      if (res.status) {
        this.cmtTypeList = res.data;
        this.getLicenseType();
      }
      else { this.toastr.error('Load comment types failed'); }
    });
  }

  changeTab(tab: any) {
    $('.tab-pane.box').removeClass('active');
    this.curTab = tab;
    setTimeout(() => {
      $('#' + this.curTab.id).addClass('active');
      // if (this.curTab.id == 'users') {
      //   this.getAllUsers();
      // }
    });
  }

  goToPage(n: number): void {
    if (this.filter.pageNo === n) {
      return;
    }
    this.filter.pageNo = n;
    this.getAllUsers();
  }

  onNext(): void {
    this.filter.pageNo = +this.filter.pageNo;
    this.filter.pageNo++;
    this.getAllUsers();
  }

  onPrev(): void {
    this.filter.pageNo = +this.filter.pageNo;
    this.filter.pageNo--;
    this.getAllUsers();
  }

  getAllUsers() {
    this.loadingUser = true;
    this.users = [];
    this.apiLoadUsers().then(response => {
      this.loadingUser = false;
      if (response.status && response.data.total > 0) {
        this.users = response.data.users;
        this.pageCount = response.data.total;
      } else {

      }
    }).catch(() => {
      this.loadingUser = false;
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  apiLoadUsers() {
    if (this.isWorkerRole) return this.userService.getWokersByRoleFromAdmin(this.filter);
    return this.userService.getUsersByRoleFromAdmin(this.filter);
  }

  changeCategory(role) {
    if (role.categoryId == 1) { role.permissions = this.originPermissions.filter(x => x.general); }
    else if (role.categoryId == 4) { role.permissions = this.originPermissions.filter(x => x.drawing); }
    else if (role.categoryId == 5) { role.permissions = this.originPermissions.filter(x => x.administrative); }
    else if (role.categoryId == 6) { role.permissions = this.originPermissions.filter(x => x.security); }
    else if (role.categoryId == 7) { role.permissions = this.originPermissions.filter(x => x.reporting); }
    else if (role.categoryId == 8) { role.permissions = this.originPermissions.filter(x => x.item); }
  }

  deleteRole() {
    this.permissionService.deleteRole(this.rolesIdDelete).then(response => {
      this.modalDelete.close();
      if (response.status) {
        this.toastr.success('Role has been removed');
        this.router.navigate(['roles']);
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  unAssigned() {
    this.loadingUser = true;
    this.apiRemoveRole().then((res) => {
      if (!res.status) {
        this.toastr.error('Can\'t unassign');
        return;
      }
      this.getAllUsers();
    }).finally(() => this.loadingUser = false);
  }

  apiRemoveRole() {
    if (this.isWorkerRole) return this.userService.unassignWorkerRole(this.userUnassign, this.roleUnassign);
    return this.userService.unassignRole(this.userUnassign, this.roleUnassign);
  }

  // assignOrUnassign(licenseTypeId, roleId, isChecked) {
  //   this.licenseTypeService.assignOrUnassignLicenseTypeToRole(licenseTypeId, roleId, isChecked)
  //     .then((res) => {
  //       if (!res.status) {
  //         this.toastr.error(res.message);
  //         return;
  //       }
  //       this.toastr.success('License Type has been assigned.');
  //     });
  // }

  assignOrUnassign() {
    if(this.curTab.id == 'sys_permissions'){
      this.enableBtnSave = true;
    }
  }

  editRole(role: any) {
    this.editRoleForm = this.fb.group({
      roleId: [role.roleId],
      roleName: [role.roleName, Validators.required],
      roleDescription: [role.roleDescription],
      estimator: [role.estimator],
      qaTester: [role.qaTester],
      qxmOnly: [role.qxmOnly],
      clientVisible: [role.clientVisible],
      tenantId: [this.role.tenantId],
      // permissions: [[...role.permissions, ...role.adminPermissions]],
      permissions: [role.permissions],
      roleCommentDocumentLevelAccessIds: [[]],
      channelIds: [this.channelList.filter(i => i.checked).map(i => Number(i.id)) || []],
      hasChangeRoleDocumentTypes: [this.hasChangeRoleDocumentTypes],
      roleDocumentTypes: [this.docTypeList.filter(x => x.allowView || x.allowAdd || x.allowEdit || x.allowDelete)],
      hasChangeRoleCommentTypes: [this.hasChangeRoleCommentTypes],
      roleCommentTypes: [this.cmtTypeList.filter(x => x.allowView || x.allowAdd || x.allowEdit || x.allowDelete)],
    });

    if (this.isWorkerRole) {
      this.editRoleForm.patchValue({
        roleCommentDocumentLevelAccessIds: this.listCommentDocumentLevelAccess.filter(c => c.checked == true).map(c => c.id),
      });
    }

    if (this.editRoleForm.valid) {
      this.permissionService.editRoleWithPermissions(this.editRoleForm.value).then(response => {
        if (response.status) {
          if(this.curTab.id == 'sys_permissions'){
            this.licenseTypes.forEach(x => {
              this.licenseTypeService
                  .assignOrUnassignLicenseTypeToRole(x.licenseTypeId, role.roleId, x.assigned)
                  .then((res) => {});
            });
          }
          this.toastr.success('Role has been updated');
          this.hasChangeRoleDocumentTypes = false;
          this.hasChangeRoleCommentTypes = false;
          this.router.navigate(['roles']);
        } else {
          response.message ? this.toastr.error(response.message) : this.toastr.error('Edit role failed');
        }
      });
    }
  }

  viewAll(role = null) {
    this.doCheckAllWhenEdit('VIEW', role.checkAllViewEdit, role);
    if (!role.checkAllViewEdit) {
      role.checkAllAddEdit = false;
      role.checkAllEditEdit = false;
      role.checkAllDeleteEdit = false;
    }
  }

  addAll(role = null) {
    this.doCheckAllWhenEdit('ADD', role.checkAllAddEdit, role);
  }

  editAll(role = null) {
    this.doCheckAllWhenEdit('EDIT', role.checkAllEditEdit, role);
  }

  deleteAll(role = null) {
    this.doCheckAllWhenEdit('DELETE', role.checkAllDeleteEdit, role);
  }

  private doCheckAllWhenEdit(type, check, role) {
    this.enableBtnSave = true;
    role.permissions.forEach(this.doCheckAll(type, check));
  }

  private doCheckAll(type: any, check: any): any {
    return e => {
      switch (type) {
        case 'VIEW':
          if (!e.hideView && !e.disabledView) {
            e.allowView = check;
            this.viewCheckChange(check, e);
          }
          break;
        case 'ADD':
          if (!e.hideAdd && e.allowView) {
            e.allowAdd = check;
          }
          break;
        case 'EDIT':
          if (!e.hideEdit && e.allowView) {
            e.allowEdit = check;
          }
          break;
        case 'DELETE':
          if (!e.hideDelete && e.allowView) {
            e.allowDelete = check;
          }
          break;
      }
    };
  }

  viewCheckChange(e, per) {
    this.enableBtnSave = true;
    if (!e) {
      per.allowAdd = false;
      per.allowEdit = false;
      per.allowDelete = false;
    }
  }

  // changeCheckEstimatorEdit(role) {
  //   role.permissions.forEach(e => {
  //     switch (e.permissionId) {
  //       case ObjectPermission.Bid:
  //       case ObjectPermission.Quote:
  //       case ObjectPermission.Contract:
  //         if (!role.estimator) {
  //           e.disabledView = true;
  //           e.allowView = false;
  //           e.allowAdd = false;
  //           e.allowEdit = false;
  //           e.allowDelete = false;
  //         }
  //         else { delete e.disabledView; }
  //     }
  //   });
  // }

  check(isClick: boolean = true, hasChangeRoleDocumentTypes: boolean = false, hasChangeRoleCommentTypes: boolean = false) {
    if (isClick) this.enableBtnSave = true;
    if (hasChangeRoleDocumentTypes) this.hasChangeRoleDocumentTypes = hasChangeRoleDocumentTypes;
    if (hasChangeRoleCommentTypes) this.hasChangeRoleCommentTypes = hasChangeRoleCommentTypes;
    this.checkAllCommentDocumentLevelAccess = !this.listCommentDocumentLevelAccess.some(x => !x.checked);
    this.checkAllChannel = !this.channelList.find(i => !i.checked);
    this.checkAllDocTypeView = !this.docTypeList.find(i => !i.allowView);
    this.checkAllDocTypeAdd = !this.docTypeList.find(i => !i.allowAdd);
    this.checkAllDocTypeEdit = !this.docTypeList.find(i => !i.allowEdit);
    this.checkAllDocTypeDelete = !this.docTypeList.find(i => !i.allowDelete);
    this.checkAllCommentTypeView = !this.cmtTypeList.find(i => !i.allowView);
    this.checkAllCommentTypeAdd = !this.cmtTypeList.find(i => !i.allowAdd);
    this.checkAllCommentTypeEdit = !this.cmtTypeList.find(i => !i.allowEdit);
    this.checkAllCommentTypeDelete = !this.cmtTypeList.find(i => !i.allowDelete);
  }

  checkAll(tab: string, value: boolean, action: string = null) {
    this.enableBtnSave = true;
    switch (tab) {
      case 'comment_document_level_access':
        this.listCommentDocumentLevelAccess.forEach((x) => {
          if (x.id != CommentDocumentLevelAccess.WorkOrder) {
            x.checked = value;
          }
        });
        break;
      case 'channel': this.channelList.forEach(e => e.checked = value); break;
      case 'doc':
        this.docTypeList.forEach((x) => {
          if (action === 'VIEW') x.allowView = value;
          else if (action === 'ADD') x.allowAdd = value;
          else if (action === 'EDIT') x.allowEdit = value;
          else if (action === 'DELETE') x.allowDelete = value;
        });
        this.hasChangeRoleDocumentTypes = true;
        break;
      case 'cmt':
        this.cmtTypeList.forEach((x) => {
          if (action === 'VIEW') x.allowView = value;
          else if (action === 'ADD') x.allowAdd = value;
          else if (action === 'EDIT') x.allowEdit = value;
          else if (action === 'DELETE') x.allowDelete = value;
        });
        this.hasChangeRoleCommentTypes = true;
        break;
    }
  }

  changePermission() {
    this.enableBtnSave = true;
  }

  changeAnyAction() {
    this.enableBtnSave = true;
  }
}
