<div class="row">
  <div *ngIf="!isTenantOnly">
    <div class="col-xs-12">
      <h4 class="text-bold" style="margin-bottom: 15px">Edge Configuration</h4>
    </div>
    <div class="col-xs-12">
      <form [formGroup]="formAdd" (submit)="addEdge()" novalidate>
        <div class="row">
          <div class="col-lg-2 col-sm-3">
            <div class="form-group" [class.has-error]="edgeType.invalid && (edgeType.dirty || edgeType.touched)">
              <label class="control-label">Edge Name</label>
              <input type="text" trim="blur" class="form-control" formControlName="edgeType" maxlength="200" required>
              <span *ngIf="edgeType.invalid && (edgeType.dirty || edgeType.touched)" class="help-block">{{getErrorEdgeType()}}&nbsp;</span>
            </div>
          </div>
          <div class="col-lg-2 col-sm-3">
            <div class="form-group">
              <label class="control-label">Helper Text</label>
              <input type="text" trim="blur" class="form-control" formControlName="helperText" maxlength="200">
            </div>
          </div>
          <div class="col-lg-1 col-sm-2">
            <label class="control-label">Height</label>
            <input type="text" class="form-control text-right" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" formControlName="supplierHeight">
          </div>
          <div class="col-lg-2 col-sm-3">
            <label class="control-label">Unit Of Measure</label>
            <select class="form-control" formControlName="unitOfMeasure">
              <option [ngValue]="0" disabled>None</option>
              <option *ngFor="let t of unitsOfMeasure" [ngValue]="t.unitId">{{t.unit}}</option>
            </select>
          </div>
          <div class="col-lg-1 col-sm-2">
            <label class="control-label">Color</label>
            <input type="color" style="display: block; width: 50px;" formControlName="edgeColor">
          </div>
          <div class="col-lg-2 col-sm-3">
            <div class="form-group">
              <label class="control-label">Image</label>
              <input type="text" trim="blur" class="form-control" formControlName="image" maxlength="200" readonly>
            </div>
          </div>
          <div class="col-lg-2 col-sm-4" style="margin-top: 25px">
            <input #fileInputAdd type="file" class="d-none" (click)="fileInputAdd.value = null"
              (change)="checkValidFileAdd()" />
            <button type="button" class="btn btn-default btn-custom" (click)="fileInputAdd.click()">+ Image</button>
            <button type="submit" class="btn btn-primary btn-custom">Add</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Edge Type</th>
              <th *ngIf="!isTenantOnly" [style.width.px]="100">Default</th>
              <th [style.width.px]="110">Height</th>
              <th [style.width.px]="110">Unit Of Measure</th>
              <th [style.width.px]="110">Edge Color</th>
              <th>Image</th>
              <th>Helper Text</th>
              <th [style.width.px]="180">Updated On</th>
              <th [style.width.px]="200" *ngIf="!isTenantOnly"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td *ngIf="!isTenantOnly">
                    <div class="form-group" style="margin-bottom: 0" [class.has-error]="item.controls.edgeType.invalid && item.controls.edgeType.dirty && item.controls.edgeType.touched ">
                      <input type="text" maxlength="200" class="form-control" formControlName="edgeType" trim="blur" required (input)="checkDuplicate(item.controls.edgeType)" (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td *ngIf="isTenantOnly">{{item.value.edgeType}}</td>
                  <td *ngIf="!isTenantOnly" [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="isDefaultAssignForNewTenant" (change)="save()">
                  </td>
                  <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" formControlName="supplierHeight" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="110" *ngIf="!isTenantOnly">
                    <div class="form-group" style="margin-bottom: 0">
                      <select class="form-control" formControlName="unitOfMeasure" (change)="save()">
                        <option [ngValue]="0" disabled>None</option>
                        <option *ngFor="let t of unitsOfMeasure" [ngValue]="t.unitId">{{t.unit}}</option>
                      </select>
                    </div>
                  </td>
                  <td [style.width.px]="110" class="text-center">
                    <input type="color" formControlName="edgeColor" (change)="save()">
                  </td>
                  <td *ngIf="!isTenantOnly">{{item.value.image}}</td>
                  <td *ngIf="!isTenantOnly">
                    <input type="text" trim="blur" maxlength="200" class="form-control" formControlName="helperText" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td *ngIf="isTenantOnly">{{item.value.helperText}}</td>
                  <td [style.width.px]="180">{{item.value.updatedOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td [style.width.px]="200" *ngIf="!isTenantOnly">
                    <input #fileInputRow type="file" class="d-none" (change)="UpdateImage($event, item.value.edgeId)" />
                    <button type="button" class="btn btn-default btn-custom" (click)="fileInputRow.click()">+ Image</button>
                    <button type="button" class="btn btn-primary btn-custom" (click)="deleteEdge(item.value.edgeId)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this edge? Removing the edge type will convert all bids with this edge to None.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
