import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-global-edge',
  templateUrl: './global-edge.html',
  styleUrls: ['./global-edge.scss']
})

export class EdgeGlobalComponent implements OnInit {
  @Input() isTenantOnly: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('fileInputAdd') fileInputAdd;
  @ViewChild('fileInputRow') fileInputRow;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  formAdd: FormGroup;
  unitsOfMeasure = [];
  edgeTypeId: any;
  tmpUploadFilesAdd: any;
  isEmptyFile = false;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.settingService.getAllUnitsOfMeasure().then(response => {
      if (response.status) this.unitsOfMeasure = response.data.filter(x => x.unitType == 1);
      else this.toast.error('Can\'t get list units of measure');
    });
    this.tmpUploadFilesAdd = [];

    this.loadData();
    this.formAdd = this.fb.group({
      tenantId: [0],
      edgeType: ['', Validators.required],
      edgeAlias: [''],
      helperText: [''],
      fabricated: [true],
      onsite: [false],
      frequentlyUsed: [false],
      unitOfMeasure: [0],
      edgeColor: ['#2E7BAF'],
      supplierHeight: ['0.00'],
      image: [''],
      isDefaultAssignForNewTenant: [false],
    });
  }

  get edgeType(): FormControl {
    return this.formAdd.get('edgeType') as FormControl;
  }

  getErrorEdgeType() {
    return this.edgeType.hasError('required') ? 'Edge name cannot be empty' : '';
  }

  loadData() {
    this.loading = true;
    this.settingService.getEdgeTypes(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  bindUploadDocument() {
    this.formAdd.patchValue({
      image: this.tmpUploadFilesAdd[0].name
    })
  }

  checkValidFileAdd() {
    let fi = this.fileInputAdd.nativeElement;
    if (fi.files && fi.files[0]) {
      this.isEmptyFile = false;
      for (let i = 0; i != fi.files.length; i++) {

        this.tmpUploadFilesAdd.push(fi.files[i]);
      }
    }
    else {
      this.isEmptyFile = true;
    }
    this.bindUploadDocument();
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  async UpdateImage(e, id) {
    let fi = e.target;
    var filesUpload = [];
    if (fi.files && fi.files[0]) {
      for (let i = 0; i != fi.files.length; i++) {

        filesUpload.push(fi.files[i]);
      }
    }

    if (filesUpload.length > 0) {
      this.isEmptyFile = false;
      const formData: FormData = new FormData();
      for (let i = 0; i < filesUpload.length; i++) {
        formData.append('filesRequest', filesUpload[i]);
      }
      formData.append('id', id);
      formData.append('docType', '0');
      formData.append('fileTypeId', '0');
      formData.append('description', '');
      formData.append('scopeId', '13');
      await this.settingService.uploadFile(formData).then(data => {
        var item = this.data.find(x => x.edgeId == id);
        item.fileName = filesUpload[0].name;

        this.dataFilter = this.data;
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
          dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
          dataUpdate: this.fb.array(dataUpdate)
        });

        if (data.status) {
          this.toast.success('image has been updated');
        }
        else {
          this.toast.error('Upload image failed');
        }
      }).catch(ex => {
        this.toast.error('Upload image failed');
      });
    }

  }

  createItemView(e): FormGroup {
    return this.fb.group({
      edgeId: e.edgeId,
      edgeType: [e.edgeType, Validators.required],
      edgeAlias: e.edgeAlias,
      helperText: e.helperText,
      fabricated: e.fabricated,
      onsite: e.onsite,
      frequentlyUsed: e.frequentlyUsed,
      updatedOn: e.updatedOn,
      unitOfMeasure: e.unitOfMeasure,
      edgeColor: e.edgeColor,
      supplierHeight: e.supplierHeight,
      image: e.fileName,
      isDefaultAssignForNewTenant: e.isDefaultAssignForNewTenant,
    });
  }

  async uploadFile(id) {
    if (this.tmpUploadFilesAdd.length > 0) {
      this.isEmptyFile = false;
      const formData: FormData = new FormData();
      for (let i = 0; i < this.tmpUploadFilesAdd.length; i++) {
        formData.append('filesRequest', this.tmpUploadFilesAdd[i]);
      }
      formData.append('id', id);
      formData.append('docType', '0');
      formData.append('fileTypeId', '0');
      formData.append('description', '');
      formData.append('scopeId', '13');
      return await this.settingService.uploadFile(formData).then(data => {
        if (data.status) {
          this.tmpUploadFilesAdd = [];
          return data.data;
        }
        else {
          this.toast.error('Upload image failed');

          return 0;
        }
      }).catch(ex => {
        this.toast.error('Upload image failed');

        return 0;
      });
    }
  }

  async addEdge() {
      this.edgeType.markAsDirty();
      this.edgeType.markAsTouched();
      if (this.formAdd.valid) {
        this.loading = true;
        this.formAdd.disable();;
        var data = this.formAdd.value;
        data.documentId = 0;
        await this.settingService.addEdgeTypes(data).then(async (response)=> {
          if (response.status) {
            await this.uploadFile(response.data);
            this.formAdd.patchValue({
              tenantId: 0,
              edgeType: '',
              edgeAlias: '',
              helperText: '',
              fabricated: false,
              onsite: false,
              frequentlyUsed: false,
              image: ''
            });
            this.formAdd.markAsPristine();
            this.formAdd.markAsUntouched();
            this.toast.success('New Edge has been added');
            this.reloadStatusBatchVersions.emit();
            this.loadData();
          }
          else {
            if (response.data == 0) {
              this.toast.error('Edge was not added successfully. Be sure to check the required fields to ensure you\'ve provided the proper information. Additionally, each edge must be unique.');
            }
            else if (response.data == -1) {
              this.toast.error('Edge color already exists. Edge color should be uniquely defined.');
            }
            else {
              this.toast.error('The Edge is added fail');
            }
            this.loading = false;
          }
          this.formAdd.enable();
        })
    }
  }

  deleteEdge(edgeTypeId) {
    this.modalConfirm.open();
    this.edgeTypeId = edgeTypeId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteEdgeType(this.edgeTypeId).then(response => {
      if (response.status) {
        this.toast.success('This edge has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.edgeId == e.edgeId);
    item.edgeType = e.edgeType;
    item.edgeAlias = e.edgeAlias;
    item.helperText = e.helperText;
    item.fabricated = e.fabricated;
    item.onsite = e.onsite;
    item.frequentlyUsed = e.frequentlyUsed;
    item.edgeColor = e.edgeColor;
    return {
      edgeId: e.edgeId,
      edgeType: e.edgeType,
      edgeAlias: e.edgeAlias,
      helperText: e.helperText,
      fabricated: e.fabricated,
      onsite: e.onsite,
      frequentlyUsed: e.frequentlyUsed,
      isGlobal: true,
      unitOfMeasure: e.unitOfMeasure,
      edgeColor: e.edgeColor,
      supplierHeight: e.supplierHeight,
      isDefaultAssignForNewTenant: e.isDefaultAssignForNewTenant,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (this.dataForm.invalid) {
      this.toast.error(' Edge cannot be empty and must be unique');
      isValid = false;
    }
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateEdges(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Edge has been updated');
          if (dataUpdate.length > 0) {
            // this.settingService.setBidsIsRefesh(this.tenantId);
            this.reloadStatusBatchVersions.emit();
          }
        }
        else {
          this.toast.error(response.message ?? 'Update edge failed');
          this.loadData();
        }
      });
    }
  }

  checkDuplicate(e: FormControl) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      if (e.parent.value.edgeId != m.value.edgeId && e.parent.value.edgeType.trim().toUpperCase() == m.value.edgeType.trim().toUpperCase()) {
        e.setErrors({ 'hasDup': true });
      }
    });
  }
}
