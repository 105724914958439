import { LicenseTypeService } from './../../services/license-type.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SettingService } from '@app-admin/services/setting.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
declare var $: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-roles',
  templateUrl: './roles.html',
  styleUrls: ['./roles.scss']
})

export class RolesComponent implements OnInit {

  @ViewChild('modalDelete') modalDelete: BsModalComponent;

  loading = false;
  roles: any;
  rolesFilter: any;
  tenants: any;
  tenantId = 0;
  addRoleForm: FormGroup;
  moduleSelectedId = 1;
  channelList = [];
  filterVisible = 0;
  serviceSelectedId = 0;
  listLicenseType: any = [];
  listModuleType = {
    0:'All',
    1:'Sales Pro',
    2:'Order Pro',
    3:'Schedule Pro',
    6:'Shop Pro',
    7:'Field Pro',
    8:'Inventory Pro',
    4:'Director',
    5:'Client Administrator'
  }

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private userService: UserService,
    private permissionService: PermissionService,
    private licenseTypeService: LicenseTypeService,
    private settingService: SettingService) {
    this.addRoleForm = this.fb.group({
      roleName: ['', Validators.required],
      roleDescription: [''],
      estimator: [false],
      qaTester: [false],
      qxmOnly: [false],
      clientVisible: [true],
      tenantId: [''],
      permissions: [null],
      channelIds: [[]],
      documentTypeIds: [[]],
      commentTypeIds: [[]],
      roleServices: [[]],
      licenseTypeRoles: [[]],
      channelRoles: [[]],
      moduleId: this.moduleSelectedId,
      serviceId: [1]
    });
  }

  ngOnInit() {
    $('body').layout('fix');

    if (sessionStorage.getItem('tenantId')) { this.tenantId = Number(sessionStorage.getItem('tenantId')); }

    this.getAllLicenseType();
    this.getRoles();

    this.settingService.getTenant().then(response => {
      if (response.status) { this.tenants = response.data; }
      else { this.toastr.error('Can\'t get list tenant'); }
    });

    this.getChannels();
  }

  get roleName(): FormControl {
    return this.addRoleForm.get('roleName') as FormControl;
  }
  getErrorRoleName() {
    return this.roleName.hasError('required') ? 'Role name is required' : '';
  }

  private getAllLicenseType() {
    this.licenseTypeService.getLicenseTypeByModuleTenant(this.moduleSelectedId, this.tenantId).then(res => {
      if (!res.status) { this.toastr.error('Can\'t get list license types'); return; }
      this.listLicenseType = res.data;
    });
  }

  private getChannels() {
    this.settingService.getDefaultChannels(this.tenantId, this.moduleSelectedId, 0).then(res => {
      if (res.status) {
        // this.channels = res.data;
        this.channelList = res.data.map(c => ({ id: c.channelId, itemName: c.channelName }));
      }
      else {
        this.toastr.error('Load channels failed');
      }
    });
  }

  getRoles() {
    sessionStorage.setItem('tenantId', this.tenantId.toString());
    if (this.tenantId < 1) { return; }
    this.loading = true;
    this.permissionService.getRolesWebAdmin(this.tenantId, this.moduleSelectedId).then(response => {
      if (response.status) {
        this.roles = response.data;

        if (this.tenantId === 0) {
          this.rolesFilter = JSON.parse(JSON.stringify(this.roles)).filter(r => !r.tenantId);
        } else {
          this.rolesFilter = JSON.parse(JSON.stringify(this.roles)).filter(r => (r.tenantId === this.tenantId || r.TenantId == null)
            && (r.moduleId == this.moduleSelectedId || r.moduleId == null || this.moduleSelectedId == 0)
            && (this.filterVisible != 0 ? this.filterVisible == 1 ? !!r.clientVisible : !!r.qxmOnly : true)
            && (this.serviceSelectedId == 0 || _.indexOf(r.serviceIds, Number(this.serviceSelectedId)) !== -1));
        }
        this.loading = false;
      } else {
        this.toastr.error('Load roles failed');
      }
    });
  }

  changeModule() {
    this.serviceSelectedId =  0;
    this.addRoleForm.get('serviceId').setValue(1);
    this.getAllLicenseType();
    this.getRoles();
  }

  changeTenant() {
    this.getAllLicenseType();
    this.getChannels();
    this.getRoles();
  }

  changeFilterVisible() {
    this.getRoles();
  }

  changeService() {
    this.getRoles();
  }

  addNewRole() {
    this.roleName.markAsDirty();
    this.roleName.markAsTouched();
    if (this.addRoleForm.valid) {
      this.addRoleForm.patchValue({
        tenantId: this.tenantId,
        moduleId: this.moduleSelectedId,
        channelRoles: this.channelList.map(x => ({ channelId: x.id })),
        documentTypeIds: [],
        commentTypeIds: [],
        roleServices: [{ serviceId: this.addRoleForm.get('serviceId').value }],
        licenseTypeRoles: this.listLicenseType.filter(x => x.checked).map(xx => ({ licenseTypeId: xx.licenseTypeId }))
      });

      let data = _.pick(this.addRoleForm.value,
                        [
                          'roleName',
                          'roleDescription',
                          'estimator',
                          'qaTester',
                          'qxmOnly',
                          'clientVisible',
                          'tenantId',
                          'moduleId',
                          'channelRoles',
                          'roleServices',
                          'licenseTypeRoles',
                        ]);

      this.permissionService.addRoleNoPermissions(data).then(response => {
        if (response.status) {
          this.router.navigate(['/role-detail/' + response.data]);
          this.toastr.success('Role has been added');
        } else { response.message ? this.toastr.error(response.message) : this.toastr.error('Add role failed'); }
      });
    }
  }
}
