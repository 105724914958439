import { Injectable } from '@angular/core';
declare var $: any; // Declare jQuery

@Injectable({
  providedIn: 'root'
})
export class ReassignChannelModalService {

  /**
   * Opens a modal by its ID
   * @param id - The ID of the modal HTML element
   */
  open(id: string): void {
    $('#' + id).modal('show');
  }

  /**
   * Closes a modal by its ID
   * @param id - The ID of the modal HTML element
   */
  close(id: string): void {
    $('#' + id).modal('hide');
  }
}
